// ** React Imports
import { useEffect, useState } from "react";

// ** Components Imports
import { H3 } from "../../../AbstractElements";

// ** Third party Imports
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// ** Store Imports
import {
  news_list,
  setNewsData,
} from "../../../ReduxToolkit/Reducer/Dashboard";
import { fetchNewsData } from "../../../ReduxToolkit/Reducer/Dashboard/dashboardAction";
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ++ Helper Imports
import AppUtils from "../../../Helper/AppUtils";

// ** Style Imports
import "swiper/css";
import "swiper/css/pagination";

const NewsBox = () => {
  // State
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const dispatch = useDispatch();
  const newsData = useSelector(news_list);
  const data = AppUtils.getLocalStorage("VORA_CUSTOMER");
  const localDate: any = AppUtils.getLocalStorage("CURR_DATE")?.dt;
  const localNews: any = AppUtils.getLocalStorage("TODAY_NEWS")?.tn;
  const currentDate = moment()
    .startOf("day")
    .add(1, "hour")
    .format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    const getNewsData = async () => {
      if (
        !localDate ||
        moment(localDate)
          .startOf("day")
          .add(1, "hour")
          .format("YYYY-MM-DD HH:mm:ss") !== currentDate ||
        localNews?.length === 0
      ) {
        setLoading(true);
        const res: any = await fetchNewsData(
          {
            date: moment()
              .startOf("day")
              .add(1, "hour")
              .format("YYYY-MM-DD HH:mm:ss"),
            country: data?.country?.short_code.toLocaleLowerCase(),
          },
          dispatch
        );
        if (res?.status === 200) {
          setLoading(false);
        }
      } else {
        dispatch(setNewsData(localNews ?? []));
      }
    };

    getNewsData();
  }, [newsData?.length]);

  return (
    <Card className="px-3 pb-3">
      <div className="d-flex  align-items-center justify-content-between py-3 border-bottom">
        <H3>News </H3>
      </div>
      {loading && (
        <div
          style={{
            display: newsData?.length === 0 ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "1.25rem",
          }}
        >
          <img
            src="/assets/svg/LoaderWhite.svg"
            style={{ width: "3rem", height: "3rem" }}
          />
          <p>Loading...</p>
        </div>
      )}

      {Array.isArray(newsData) && newsData?.length > 0 ? (
        <div className="my-3 border-bottom">
          <Card
            onClick={() =>
              window.open(newsData[0]?.url, "_blank", "noreferrer")
            }
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <Row>
                <Col md={8} className="mb-3 mb-md-0">
                  <CardImg
                    top
                    width="100%"
                    src={newsData[0]?.image}
                    alt={newsData[0]?.title}
                    style={{ maxHeight: "300px", objectFit: "cover" }}
                  />
                </Col>
                <Col md={4} className="mb-3 mb-md-0">
                  <CardTitle
                    tag="h3"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: "4",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {String(newsData[0]?.title).length > 60
                      ? String(newsData[0]?.title).slice(0, 60) + "..."
                      : newsData[0].title}
                  </CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">
                    {String(newsData[0]?.text).length > 200
                      ? String(newsData[0]?.text).slice(0, 200) + "..."
                      : newsData[0].text}
                    <div className="mt-3">
                      {moment(new Date(newsData[0]?.publish_date)).format(
                        "DD MMMM YYYY"
                      )}
                    </div>
                  </CardSubtitle>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      ) : (
        !loading && (
          <div className="text-center my-4" style={{ backgroundColor: "" }}>
            <img src="/assets/images/NoDataFound.png" />
          </div>
        )
      )}

      <div className="my-3">
        <Swiper
          spaceBetween={30}
          autoplay={{ delay: 3000, disableOnInteraction: true }}
          pagination={{ clickable: true }}
          breakpoints={{
            200: { slidesPerView: 1 },
            450: { slidesPerView: 2 },
            767: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
        >
          {Array.isArray(newsData) &&
            newsData?.length > 0 &&
            newsData
              ?.slice(1, newsData?.length)
              .map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <Card
                    onClick={() =>
                      window.open(item?.url, "_blank", "noreferrer")
                    }
                    style={{ cursor: "pointer", minHeight: "100%" }}
                  >
                    <CardImg
                      top
                      width="100%"
                      style={{ maxHeight: "8.125rem", objectFit: "cover" }}
                      src={item?.image}
                      alt={item?.title}
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: "3",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item?.title}
                      </CardTitle>

                      <CardSubtitle tag="h6" className="mt-2 text-muted">
                        {moment(item?.publish_date).format("DD MMMM YYYY")}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </Card>
  );
};

export default NewsBox;
