import React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Btn, H5, H4, H6 } from '../../../../AbstractElements';
import moment from 'moment';

const NotificationDetailsModel = (props: any) => {
    const { isOpen, toggle, data } = props;

    const getModuleNmme = (text: string) => {
        return text === 'chat' ? "Quotation Chat"
            : text === "quotation" ? "Quotation"
                : text === 'product_inquiry' ? 'Product Inquiry'
                    : text === 'quick_inqiry' ? 'Quick Inquiry'
                        : text === 'inquiry_form' ? 'Contact Inquiry'
                            : ""
    }

    return (
        <Modal centered={true} isOpen={isOpen} size="lg">
            <ModalHeader toggle={toggle}>
                <H4>Notification Details</H4>
            </ModalHeader>
            <ModalBody>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Title</H6>
                    </Col>
                    <Col xs="8">{data?.title || 'N/A'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Message</H6>
                    </Col>
                    <Col xs="8">{data?.message || 'N/A'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Sent by</H6>
                    </Col>
                    <Col xs="8">{data?.sent_by || 'N/A'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Module</H6>
                    </Col>
                    <Col xs="8">{getModuleNmme(data?.module) || 'N/A'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Priority</H6>
                    </Col>
                    <Col xs="8">{data?.priority === 0 ? 'Low' : data?.priority === 1 ? 'Normal' : 'High'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="4">
                        <H6>Created At</H6>
                    </Col>
                    <Col xs="8">{moment(data?.createdAt).format("DD-MM-YYYY hh:mm A") || 'N/A'}</Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end align-items-center">
                    <Btn
                        color="secondary"
                        className="ms-3"
                        style={{ width: "max-content" }}
                        onClick={toggle}
                    >
                        Close
                    </Btn>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default NotificationDetailsModel;
