// ** Components Imports
import { Image, LI, UL, FeatherIcons, P } from "../../../../AbstractElements";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// ** Third Party Imports
import { auth_data } from "../../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Helper Imports
import AppUtils from "../../../../Helper/AppUtils";
import { dynamicImage } from "../../../../Service";

const UserProfile = () => {
  // Hooks
  const navigate = useNavigate();
  const authData = useSelector(auth_data);

  const homeURL = process.env.REACT_APP_HOME_URL

  return (
    <LI className="profile-nav onhover-dropdown">
      <Image className="b-r-10" src={dynamicImage("1.png")} alt="profile" />
      <UL
        className="profile-dropdown onhover-show-div"
        style={{ width: "auto" }}
      >
        <LI style={{ display: "flex", alignItems: "center", minWidth: "250px" }}>
          <img src="/assets/images/1.png" style={{ borderRadius: "50%" }} />
          <div className="ms-3">
            <P>{authData?.name ? authData?.name : "N/A"}</P>
            <span>{authData?.email ? authData?.email : "N/A"}</span>
          </div>
        </LI>
        <LI
          style={{ minWidth: "250px" }}
          onClick={() => window.open(`${homeURL}/login?token=${encodeURIComponent(authData?.token)}`)
          }
        >
          <FeatherIcons iconName={"Home"} />
          <span>Go To Home</span>
        </LI>
        <LI
          style={{ minWidth: "250px" }}
          onClick={() => navigate('/profile')}
        >
          <FeatherIcons iconName={"User"} />
          <span>User Profile</span>
        </LI>
        <LI
          style={{ minWidth: "250px" }}
          onClick={() => {
            localStorage.removeItem("VORA_CUSTOMER");
            localStorage.removeItem("CURR_DATE");
            localStorage.removeItem("TODAY_NEWS");
            navigate("/login");
          }}
        >
          <FeatherIcons iconName={"LogOut"} />
          <span>Log Out</span>
        </LI>
        {/* <LI
          style={{ minWidth: "250px" }}
          onClick={() => navigate("/change-password")}>
          <FeatherIcons iconName={"Key"} />
          <span>Change Password</span>
        </LI> */}
      </UL>
    </LI>
  );
};

export default UserProfile;
