// ** Component Imports
import { Input, Label } from "reactstrap";

// ** Third Party Imports
import { useSelector } from "react-redux";
import { P } from "../../AbstractElements";

const TextField = (props: any) => {
  // Props
  const {
    value,
    onChange,
    name,
    className,
    style,
    label,
    error,
    helperText,
    placeholder,
    type,
    boxStyle,
    boxClass,
    iconJsx,
    readOnly,
    maxLength,
    disabled,
    multiple,
  } = props;

  // Hooks
  const { darkMode } = useSelector((state: any) => state.layout);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const spaceRegex = /^\s/;
    const inputValue = event.target.value;

    if (!spaceRegex.test(inputValue)) {
      if (!maxLength || inputValue.length <= maxLength) {
        onChange(event);
      }
    }
  };

  return (
    <div style={boxStyle} className={boxClass}>
      <div className="d-flex justify-content-between">
        {label && (
          <Label
            className="form-label"
            style={{ color: darkMode ? "white" : "black" }}
          >
            {label}
          </Label>
        )}
        {maxLength && (
          <Label
            className="form-label"
            style={{ color: darkMode ? "white" : "black" }}
          >
            {value?.length} / {maxLength}
          </Label>
        )}
      </div>

      <Input
        {...props}
        disabled={disabled}
        readOnly={readOnly}
        style={{
          ...style,
          color: darkMode ? "rgba(255, 255, 255, 0.7)" : "black",
        }}
        className={`${className}`}
        type={type ? type : "text"}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={handleChange}
        maxLength={maxLength}
        multiple={multiple}
      />
      {iconJsx ? iconJsx : null}
      {error && <div className="text-danger ps-1 pt-1">{helperText}</div>}
    </div>
  );
};

export default TextField;
