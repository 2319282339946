// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface DashboardState {
    loginHistory: any[];
    loginHistoryRecord: number;
    isLoading: boolean;

}

const dashboardState: DashboardState = {
    loginHistory: [],
    isLoading: false,
    loginHistoryRecord: 0,

};

const LogsSlice = createSlice({
    name: "logs",
    initialState: dashboardState,
    reducers: {
        setLoginHistory(state: any, action: any) {
            state.loginHistory = action.payload;
        },
        setLoginHistoryCount(state: any, action: any) {
            state.loginHistoryRecord = action.payload;
        },
        setLoading(state: any, action: any) {
            state.isLoading = action.payload;
        },

    },
});

export const {
    setLoginHistory,
    setLoginHistoryCount,
    setLoading,
}: any = LogsSlice.actions;

export default LogsSlice.reducer;

export const login_history = (state: any) => state.logs.loginHistory;

export const is_loading = (state: any) => state.logs.isLoading;

export const login_history_record = (state: any) => state.logs.loginHistoryRecord;
