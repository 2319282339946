// ** React Imports
import { useEffect, useState } from "react";

// ** Components Imports
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import TextField from "../../Components/TextField";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  isMobile,
  isTablet,
  isDesktop,
  isMacOs,
  isSmartTV,
  osName,
  isIPad13,
  isIPhone13,
} from "react-device-detect";
import { publicIpv4 } from "public-ip";

// ** Store Imports
import { customerLogin, fetchCusomerById } from "../../ReduxToolkit/Reducer/Login/LoginAction";

// ** Icon Imports
import { FeatherIcons } from "../../AbstractElements";

// ** Helper Imports
import { dynamicImage } from "../../Service";
import AppUtils from "../../Helper/AppUtils";
import { jwtDecode } from "jwt-decode";

interface Login {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginPage = () => {
  // State
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: Login = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const schema = yup.object({
    email: yup
      .string()
      .required("Please enter email")
      .email("Please enter valid email"),
    password: yup
      .string()
      .required("Please enter password")
      .min(8, "Password must be 8 characters"),
    rememberMe: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: Login) => {
      setLoading(true);
      const { rememberMe, ...other } = value;
      const res = await customerLogin(
        {
          ...other,
          device_type: isMobile
            ? "mobile"
            : isTablet
              ? "tablet"
              : isSmartTV
                ? "smart tv"
                : isDesktop
                  ? "desktop"
                  : isMacOs
                    ? "mac"
                    : isIPad13
                      ? "iPad"
                      : isIPhone13
                        ? "iPhone"
                        : "",
          ip_address: ipAddress,
          operating_system: osName,
          panel: "Customer",
        },
        dispatch,
        rememberMe
      );
      if (res?.status) {
        formik.resetForm();
        navigate(`/dashboard`);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const rmbMeData = localStorage.getItem("VORA_CUS_REM");
    if (rmbMeData && rmbMeData !== undefined && rmbMeData !== null) {
      const uData = JSON.parse(rmbMeData);
      if (Object.keys(uData).length > 0) {
        formik.setFieldValue("rememberMe", true);
        formik.setFieldValue("email", AppUtils.decodeStr(uData && uData?.uN));
        formik.setFieldValue(
          "password",
          AppUtils.decodeStr(uData && uData?.uP)
        );
      }
    }
  }, []);

  useEffect(() => {
    const getAsyncIpAddress = async () => {
      try {
        const pIP = await publicIpv4();
        setIpAddress(pIP || "");
      } catch (e: any) {
        setIpAddress("");
      }
    };

    getAsyncIpAddress();
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = decodeURIComponent(searchParams.get("token") || '');

    const fetchAutoUser = async () => {
      if (token) {
        try {
          const decoded: any = jwtDecode(token);
          if (decoded) {
            const res = await fetchCusomerById({ id: decoded?.id, token: token }, dispatch);
            res?.status && navigate('/dashboard');
          }
        } catch (error) {
          console.error('Token decoding error:', error);
        }
      }
    }

    token && fetchAutoUser();
  }, [navigate, dispatch]);

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div className="login-main p-4">
              <Form className="theme-form" onSubmit={formik.handleSubmit}>
                <Image
                  className="img-fluid mx-auto mb-3"
                  src={dynamicImage("logo/LOGO.svg")}
                  alt="Vora Spices"
                />
                <H4>Sign in to account</H4>
                <P className="mt-3">Enter your email & password to login</P>
                <TextField
                  boxClass="mb-3"
                  label={"Email Address"}
                  name={"email"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Enter Email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  label={"Password"}
                  boxClass="mb-3"
                  name={"password"}
                  type={show ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Enter Password"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  boxStyle={{ position: "relative" }}
                  iconJsx={
                    <div
                      style={{
                        position: "absolute",
                        right: "0.625rem",
                        top: "2.1rem",
                        cursor: "pointer",
                      }}
                    >
                      <FeatherIcons
                        onClick={() => setShow(!show)}
                        iconName={show ? "Eye" : "EyeOff"}
                      />
                    </div>
                  }
                />
                <FormGroup className="mb-0 form-sub-title position-relative">
                  <div className="checkbox p-0">
                    <Input
                      id="checkbox1"
                      type="checkbox"
                      checked={formik.values.rememberMe}
                      onChange={() =>
                        formik.setFieldValue(
                          "rememberMe",
                          !formik.values.rememberMe
                        )
                      }
                    />
                    <Label className="text-muted" htmlFor="checkbox1">
                      Remember Me
                    </Label>
                  </div>
                  <div className="text-end mt-3">
                    <Btn
                      color="primary"
                      block
                      className="w-100"
                      disabled={loading}
                    >
                      Sign In
                    </Btn>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
