// ** React Imports
import { useCallback, useEffect, useRef, useState } from "react";

// ** Components Imports
import { Form, Input } from "reactstrap";
import { Btn, P, Image } from "../../../../../AbstractElements";

// ** Third Party Imports
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// ** Store Imports
import { curr_quote } from "../../../../../ReduxToolkit/Reducer/Quotes";
import {
  addMessage,
  all_messages,
} from "../../../../../ReduxToolkit/Reducer/Chat";
import {
  fetchAllMessages,
  sendMessage,
  updateMessageStatus,
} from "../../../../../ReduxToolkit/Reducer/Chat/ChatAction";
import { auth_data } from "../../../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Config Imports
import socket from "../../../../../Config/socket";

// ** Helper Imports
import AppUtils from "../../../../../Helper/AppUtils";

const UserChat = () => {
  // State
  const [message, setMessage] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  // Hooks
  const dispatch = useDispatch();
  const authUser = useSelector(auth_data);
  const allMessage = useSelector(all_messages);
  const currQuote = useSelector(curr_quote);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { darkMode } = useSelector((state: any) => state.layout);

  const getMessage = useCallback(() => {
    fetchAllMessages(
      {
        customer_id: authUser?.data?.id,
        quotation_id: currQuote?._id,
      },
      dispatch
    );
  }, [currQuote?._id]);

  useEffect(() => {
    getMessage();
  }, [getMessage]);

  useEffect(() => {
    socket.on("incomingChatMessage", (value: any) => {
      const messageValue = JSON.parse(value);
      if (
        messageValue?.quotation_id === currQuote?._id &&
        messageValue?.customer_id === authUser?._id
      ) {
        dispatch(addMessage(messageValue));
      }
    });
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [allMessage]);

  return (
    <>
      <div className="right-sidebar-Chats">
        <div className="msger">
          <div className="msger-chat" ref={chatContainerRef}>
            {Array.isArray(allMessage) && allMessage.length > 0 ? (
              allMessage.map((item: any, id: number) => {
                const showDateDivider =
                  id === 0 ||
                  !moment(item.createdAt).isSame(
                    moment(allMessage[id - 1].createdAt),
                    "day"
                  );

                return (
                  <div key={id} className="mb-2">
                    {showDateDivider && (
                      <div className="date-divider text-center mb-2">
                        <P className="mb-0">
                          {moment(item.createdAt).format("MMMM DD, YYYY")}
                        </P>
                      </div>
                    )}
                    <div
                      className={`msg ${
                        item.sent_by === "CUSTOMER" ? "right" : "left"
                      }-msg`}
                      key={id}
                    >
                      {item?.name ? (
                        <div className="msg-img" />
                      ) : (
                        <Image
                          src={"/assets/images/user/user.png"}
                          className="img-30 h-auto"
                          alt="user"
                        />
                      )}
                      <div
                        className="msg-bubble"
                        style={{
                          backgroundColor:
                            item.sent_by === "CUSTOMER"
                              ? "#006666"
                              : darkMode
                              ? "#262932"
                              : "#fff",
                        }}
                      >
                        <div className="msg-info">
                          <div
                            className="msg-info-name"
                            style={{
                              color:
                                item.sent_by == "CUSTOMER"
                                  ? "#fff"
                                  : darkMode
                                  ? "#86909c"
                                  : "rgba(82,82,108,.8)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {item.sent_by == "CUSTOMER"
                              ? "You"
                              : "Vora Spices Team".toUpperCase()}
                          </div>
                          <div
                            className="msg-info-time"
                            style={{
                              color:
                                item.sent_by == "CUSTOMER"
                                  ? "#fff"
                                  : darkMode
                                  ? "#86909c"
                                  : "rgba(82,82,108,.8)",
                            }}
                          >
                            {moment(item?.createdAt).format("hh:mm A")}
                          </div>
                        </div>
                        <div className="msg-text mt-1">
                          <P className="mb-0">{item?.message}</P>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Image
                className="img-fluid w-auto h-100 m-auto"
                src={"/assets/images/start_conversation.svg"}
                alt="start conversion"
              />
            )}
          </div>
        </div>
        {/* send message */}
      </div>
      <div style={{ position: "absolute", left: 0, bottom: 0, width: "100%" }}>
        <Form
          className="msger-inputarea"
          style={{
            background: darkMode
              ? `#1a1c23`
              : "linear-gradient(0deg, #f5f6fa, #f5f6fa), linear-gradient(0deg, #ffffff, #ffffff)",
          }}
        >
          <Input
            className="msger-input two uk-textarea"
            style={{
              marginRight: "1rem",
              color: darkMode ? "#d2d2d2" : "inherit",
              border: "0.0625rem solid grey",
              borderRadius: "8px",
            }}
            type="text"
            placeholder={"Type Message Here..."}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Btn
            color="primary"
            className="messenger-send-btn"
            disabled={!AppUtils.checkValue(message)}
            type="submit"
            onClick={async (e: any) => {
              e.preventDefault();
              if (isSubmit) return;
              setIsSubmit(true);

              const res = await sendMessage(
                {
                  customer_id: authUser?._id,
                  is_customer_seen: true,
                  is_admin_seen: false,
                  quotation_id: currQuote?._id,
                  message: message,
                  sent_by: "CUSTOMER",
                },
                dispatch
              );

              if (res.status) {
                setMessage("");
              }

              await updateMessageStatus(
                {
                  customer_id: authUser?._id,
                  quotation_id: currQuote?._id,
                },
                dispatch
              );
              setIsSubmit(false);
            }}
          >
            <i
              className="fa fa-location-arrow"
              style={{ fontSize: "1.3rem" }}
            />
          </Btn>
        </Form>
      </div>
    </>
  );
};

export default UserChat;
