// ** React Imports
import React, { useCallback, useEffect, useState } from "react"

// ** Third party Imports
import { useSelector, useDispatch } from "react-redux"

// ** Custom Imports
import { FeatherIcons, H3, H4, H5, H6, LI, UL } from "../../../AbstractElements"

// ** Component Imports
import NotificationDetailsModel from "./DetailsModel"

// ** Slice Imports
import { notification_count, notification_list } from "../../../ReduxToolkit/Reducer/Notification/NotificationSlice"
import { getNotificationList, readNotification } from "../../../ReduxToolkit/Reducer/Notification/NotificationAction"

const Notification = () => {
    // States 
    const [select, setSelect] = useState<any>(null)

    // Hooks
    const dispatch = useDispatch()
    const notifications = useSelector(notification_list)
    const counts = useSelector(notification_count)

    const fetchData = useCallback(async () => {
        await getNotificationList({ sent_by: "ADMIN" }, dispatch)
    }, [notifications?.length])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleReadNotification = async (notification: any) => {
        const res = await readNotification({ id: notification?._id, is_read: true }, dispatch)
        if (res?.status) {
            setSelect(notification)
        } else {
            setSelect(null)
        }
    }

    return (
        <>
            <LI className="onhover-dropdown">
                <div className="position-relative">
                    {counts > 0 && <span
                        style={{
                            position: "absolute",
                            top: "-1rem",
                            right: "-1.5rem",
                            backgroundColor: "#006666",
                            color: "white",
                            borderRadius: "50%",
                            fontSize: "12px",
                            lineHeight: "12px",
                            height: '25px',
                            width: '25px',
                            display: 'flex',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {counts > 99 ? counts + '+' : counts}
                    </span>}

                    <FeatherIcons className="notification" iconName="Bell" />
                </div>

                <UL className="onhover-show-div pb-3">
                    <LI className="pb-0">
                        <div className="d-flex align-items-center justify-content-between p-2">
                            <H4>Notifications</H4>
                            <H6>{counts} New</H6>
                        </div>
                        <hr />
                    </LI>
                    {notifications?.length > 0 ? notifications?.map((n: any, index: number) => (
                        <LI onClick={() => handleReadNotification(n)} className="m-0 py-0">
                            <div className="p-2 shadow-sm">
                                <div className="d-flex align-items-center mb-2 flex-row">
                                    <H6 className="text-secondary">{n?.title}</H6>
                                    <button className="btn-pill btn btn-sm btn-outline-primary px-2 py-0 ms-auto" disabled={n?.is_read}>Show</button>
                                </div>
                                <p className="text-secondary">{n?.message}</p>
                                {/* <hr className="mb-2" /> */}
                            </div>
                        </LI>
                    )) :
                        <LI>
                            <div className="flex text-center" style={{ minHeight: '100px' }}>
                                <H5>No Data Found.</H5>
                            </div></LI>
                    }

                </UL>
            </LI>

            <NotificationDetailsModel isOpen={Boolean(select)} toggle={() => setSelect(null)} data={select} />
        </>
    )
}

export default Notification