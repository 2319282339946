// ** Service Imports
import apiCall from "../../../Helper/Service";

// ** Slice Imports
import {
  deleteMessages,
  editMessageStatus,
  setAllMessage,
  setLoading,
} from ".";

export const fetchAllMessages = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "chat/fetchChatMessages",
    data: passData,
    method: "post",
    isHideTostMsg: true,
  });

  if (res?.status) {
    dispatch(setAllMessage(res?.data));
    dispatch(setLoading(false));
  }
  return res;
};

export const sendMessage = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "chat/storeChatMessage",
    data: passData,
    method: "post",
    isHideTostMsg: true,
  });

  dispatch(setLoading(false));

  return res;
};

export const updateMessageStatus = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "chat/updateMessageStatus",
    data: passData,
    method: "post",
    isHideTostMsg: true,
  });

  if (res.status) {
    dispatch(editMessageStatus(passData));
  }

  dispatch(setLoading(false));

  return res;
};

export const clearChat = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "chat/deleteMessageList",
    data: passData,
    method: "post",
    // isHideTostMsg: true,
  });

  if (res.status) {
    dispatch(deleteMessages());
  }

  dispatch(setLoading(false));

  return res;
};
