import { Col, Container } from "reactstrap";
import { Image, P, Btn,H2 } from "../../AbstractElements";

const Error404 = () => {
    return (
        <div className="page-wrapper compact-wrapper">
            <div className="error-wrapper">
                <Container>
                    <Image
                        className="img-100"
                        src={`/assets/images/sad.png`}
                        alt="otherimg"
                    />
                    <div className="error-heading">
                        <H2 className={`headline font-primary`}>404</H2>
                    </div>
                    <Col md="8" className="offset-md-2">
                        <P className="sub-content">
                            {
                                "The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved."
                            }
                        </P>
                    </Col>
                    <div>
                        <Btn
                            size="lg"
                            tag="a"
                            color={`primary`}
                            href={`/dashboard`}
                        >
                            Back To Dashboard
                        </Btn>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Error404;
