// ** React Imports
import { useState } from "react";

// ** Component Imports
import { Btn, H4 } from "../../../AbstractElements";
import { Info, MessageSquare, Trash2 } from "react-feather";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Container,
  ButtonGroup,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// ** Custom Imports
import LeftSidebar from "./Components/LeftSidebar";
import UserChat from "./Components/UserChat";
import CenteredModal from "../../../Components/DeleteDialog";

// ** Third Party Imports
import { useSelector, useDispatch } from "react-redux";

// ** Store Imports
import { curr_quote } from "../../../ReduxToolkit/Reducer/Quotes";
import { clearChat } from "../../../ReduxToolkit/Reducer/Chat/ChatAction";
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";
import { all_messages } from "../../../ReduxToolkit/Reducer/Chat";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";

const Chat = (props: any) => {
  // Props
  const { isChat, toggle } = props;

  // State
  const [isView, setIsView] = useState<boolean>(false);
  const [clearDialog, setClearDialog] = useState<boolean>(false);

  // Hooks
  const currQuote = useSelector(curr_quote);
  const authUser = useSelector(auth_data);
  const dispatch = useDispatch();
  const allMessages = useSelector(all_messages);

  return (
    <>
      <Modal isOpen={isChat} fullscreen={true} className="product-modal">
        <ModalHeader
          toggle={toggle}
          style={{ height: "3.625rem", position: "relative" }}
        >
          <H4>{currQuote?.company_name ? currQuote?.company_name : "N/A"}</H4>
          <div
            id="clear-chat"
            style={{
              backgroundColor: "#fe6a49",
              textAlign: "center",
              padding: "0.5rem",
              width: "max-content",
              borderRadius: "50%",
              cursor:
                AppUtils.checkValue(allMessages) &&
                allMessages.filter((me: any) => me.sent_by === "CUSTOMER")
                  .length > 0
                  ? "pointer"
                  : "default",
              position: "absolute",
              right: "3rem",
              top: "0.5rem",
              opacity:
                AppUtils.checkValue(allMessages) &&
                allMessages.filter((me: any) => me.sent_by === "CUSTOMER")
                  .length > 0
                  ? 1
                  : 0.7,
            }}
            onClick={() =>
              AppUtils.checkValue(allMessages) &&
              allMessages.filter((me: any) => me.sent_by === "CUSTOMER")
                .length > 0 &&
              setClearDialog(true)
            }
          >
            <img
              src="/assets/svg/ClearChat.svg"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </div>
          <UncontrolledTooltip
            placement="bottom"
            target={`clear-chat`}
            style={{ color: "#fe6a49", borderColor: "#fe6a49" }}
          >
            Clear Chat
          </UncontrolledTooltip>
        </ModalHeader>

        <div
          className="d-flex align-items-center justify-content-end w-100 p-2 d-md-none"
          style={{ borderBottom: "0.0625rem solid rgba(82, 82, 108, 0.2)" }}
        >
          <ButtonGroup className="btn-group-pill">
            <Btn
              active={isView}
              outline
              color="danger"
              className="d-flex align-items-center"
              onClick={() => setIsView(!isView)}
            >
              <Info />
            </Btn>
            <Btn
              active={!isView}
              outline
              color="danger"
              className="d-flex align-items-center"
              onClick={() => setIsView(!isView)}
            >
              <MessageSquare />
            </Btn>
          </ButtonGroup>
        </div>
        <ModalBody style={{ padding: 0 }}>
          <Container
            fluid
            style={{ padding: 0, overflow: "hidden" }}
            className="conversation_main_wrap"
          >
            <Row className="g-0 h-100">
              <Col
                xxl="4"
                xl="6"
                md="6"
                className={`box-col-5 mt-3 pb-3 ${
                  isView ? "" : "d-none d-md-block"
                }`}
                style={{
                  height: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  paddingRight: "10px",
                }}
              >
                <LeftSidebar />
              </Col>
              <Col
                xxl="8"
                xl="6"
                md="6"
                className={`box-col-7 ${isView ? "d-none d-md-block" : ""}`}
                style={{
                  borderLeft: "1px solid rgba(82, 82, 108, 0.2)",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <UserChat />
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>

      {clearDialog && (
        <CenteredModal
          label={`You want to clear all conversation.`}
          note={"This chat is not restorable."}
          isOpen={clearDialog}
          btnText="Clear Chat"
          toggle={() => setClearDialog(false)}
          onDelete={async () => {
            await clearChat(
              {
                customer_id: authUser?._id,
                quotation_id: currQuote?._id,
              },
              dispatch
            );
            setClearDialog(false);
          }}
        />
      )}
    </>
  );
};

export default Chat;
