import { MenuItem } from "../Types/Layout/Sidebar";

export const MenuList: MenuItem[] = [
  {
    title: "General",
    lanClass: "lan-1",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard`,
      },
      {
        title: "Quotations",
        icon: "task",
        type: "link",
        path: `${process.env.PUBLIC_URL}/quotation`,
      },
      {
        title: "Login Logs",
        icon: "widget",
        type: "link",
        path: `${process.env.PUBLIC_URL}/logs`,
      },
      {
        title: "Product List",
        icon: "ecommerce",
        type: "link",
        path: `${process.env.PUBLIC_URL}/product-list`,
      },
      {
        title: "User Profile",
        icon: "user",
        type: "link",
        path: `${process.env.PUBLIC_URL}/profile`,
      },
    ],
  },
];
