// Created By MK

// ** Third Party Imports
import CryptoJS from "crypto-js";
import moment from "moment";

const AppUtils = {
  // encode the string function
  decodeStr: (str: any) => {
    return CryptoJS.AES.decrypt(
      str && str && str !== null && str !== undefined && str,
      "VORA_CUSTOMER"
    )?.toString(CryptoJS.enc.Utf8);
  },

  // decode the string function
  encodeStr: (str: string) =>
    CryptoJS.AES.encrypt(str, "VORA_CUSTOMER")?.toString(),

  // getLoccalStorage Data
  setLocalStorage: (key: string, val: any) => localStorage.setItem(key, val),

  // getLoccalStorage Data
  removeLocalStorageItem: (key: string) => localStorage.removeItem(key),

  // setLoccalStorage Data
  getLocalStorage: (key: string) => {
    const data = typeof window !== "undefined" && localStorage.getItem(key);
    return (
      data &&
      data !== null &&
      data !== undefined &&
      JSON.parse(AppUtils.decodeStr(data))
    );
  },

  // get user details
  getUserDtls: () => {
    const data = AppUtils.getLocalStorage("VORA_CUSTOMER");

    return data && data !== undefined && data !== null ? data : "{}";
  },

  // apiError handle
  apiErrorHandling: (formik: any, fields: string[], error: any) => {
    let isError = true;
    if (typeof error !== "undefined" && error !== null) {
      for (let i = 0; i < fields.length; i++) {
        if (
          typeof error[fields[i]] !== "undefined" &&
          error[fields[i]] !== null &&
          error[fields[i]] !== ""
        ) {
          formik.setFieldTouched(fields[i], true, false);
          formik.setFieldError(fields[i], error[fields[i]]);
          isError = false;
        }
      }
    }

    return isError;
  },

  roundOff: (value: number, decimals: number) => {
    return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
  },

  // check any value
  checkValue: (field: any) => {
    let isData = false;
    if (typeof field !== "undefined" && field !== null) {
      if (typeof field === "string" && field?.trim() !== "") {
        isData = true;
      } else if (typeof field === "boolean") {
        isData = true;
      } else if (typeof field === "number") {
        isData = true;
      } else if (typeof field === "object") {
        isData = true;
      }
    }

    return isData;
  },

  checkFieldValue: (data: any, field: any) => {
    let isData = false;
    if (
      AppUtils.checkValue(data) &&
      typeof data?.[field] !== "undefined" &&
      data?.[field] !== null
    ) {
      if (typeof data?.[field] === "string" && data?.[field]?.trim() !== "") {
        isData = true;
      } else if (typeof data?.[field] === "boolean") {
        isData = true;
      } else if (typeof data?.[field] === "number") {
        isData = true;
      } else if (typeof data?.[field] === "object") {
        isData = true;
      }
    }

    return isData;
  },

  // For birthdate validation
  isValidBirthdate: (val: any, isReq: boolean) => {
    if (isReq === false && AppUtils.checkValue(val) === false) {
      return true;
    }
    const value = moment(val, "MM/DD/YYYY").format("MM/DD/YYYY");
    const nDate = moment(value);
    const validDate = nDate?.isValid();
    const diff = moment().diff(value);

    const dateArr = AppUtils.checkValue(val) ? val.split("/") : [];

    if (
      AppUtils.checkValue(value) === true &&
      validDate &&
      diff >= 0 &&
      AppUtils.checkValue(val) === true &&
      val?.length === 10 &&
      AppUtils.checkFieldValue(dateArr, "length") &&
      dateArr?.length === 3 &&
      Number.isNaN(dateArr[2]) === false &&
      Number(dateArr[2]) !== 0
    ) {
      return true;
    }

    return false;
  },
  //for mobile number setup
  mobileNumberFormate: (value: string) => {
    let mNumString = value;
    let formatted = "";
    if (typeof mNumString !== "undefined" && mNumString !== null) {
      mNumString = mNumString.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";

      //for set number formate 3-3-4
      block1 = mNumString.substring(0, 3);
      if (block1?.length === 3 && mNumString?.length > 3) {
        block1 = "(" + block1 + ")-";
      }
      block2 = mNumString.substring(3, 6);
      if (block2?.length === 3 && mNumString?.length > 6) {
        block2 = block2 + "-";
      }
      block3 = mNumString.substring(6, 10);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }

      formatted = block1 + block2 + block3;
      // }
    }
    return formatted;
  },

  formatMoneyNumber: (
    nAmount: string,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
  ) => {
    let amount = nAmount;
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = Number(amount) < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substring(0, j) + thousands : "") +
        i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(Number(amount) - Number(i))
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log("e : ", e);
      return "";
    }
  },

  updateNumberWithCurrenySymbolString: (
    targetValue: string,
    oldValue: string,
    update = false
  ) => {
    const currency = "$"; // Add "$" symbol as the currency
    let newTargetVal = "";
    if (targetValue !== "") {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("-", "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 0);
      } else if (mainResult !== "" && /^\d+\.\d{0,0}$/.test(mainResult)) {
        newTargetVal = currency + mainResult;
      } else if (mainResult !== "" && /^\d+\.\d{0,1}$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 1);
      } else if (mainResult !== "" && /^\d+\.\d{0,2}$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
      } else if (mainResult !== "" && /^\d+\.\d{0,3}$/.test(mainResult)) {
        if (update) {
          newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      } else if (mainResult !== "" && mainResult === ".") {
        newTargetVal =
          currency + AppUtils.formatMoneyNumber(mainResult, 0) + ".";
      } else if (
        mainResult === "" &&
        (targetValue === "$" || targetValue === "-$")
      ) {
        newTargetVal = mainResult;
      } else {
        if (update) {
          newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      }
    } else {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 0);
      } else {
        newTargetVal = mainResult;
      }
    }
    return newTargetVal;
  },

  // Create random string
  generateRandomString() {
    const result = Math.random().toString(36).substring(2, 7);
    return result;
  },

  getFileNameFromUrl: (url: any) => {
    if (typeof url == "string") {
      const urlArr = url.split("/");
      if (Array.isArray(urlArr) && urlArr.length > 0)
        return urlArr[urlArr.length - 1];
    }

    return "";
  },

  reorderList: (
    list: Iterable<unknown> | ArrayLike<unknown>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  },

  generateSlug: (value: string) => {
    const slugValue = value
      .toLowerCase()
      .replace(/[^a-z0-9-_]+s/g, "-")
      .replace(/[^a-zA-Z0-9_\-/]/g, "-");

    return slugValue;
  },
};
export default AppUtils;
