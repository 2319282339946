import { Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import routes from "./Route";
import Error404 from '../Pages/404';

const LayoutRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, Component }, i) => (
        <>
        <Route element={<Layout />} key={i}>
          <Route path={path} element={Component}/>
        </Route>
        <Route path="*" element={<Error404 />} />
        </>
      ))}
    </Routes>
  )
}

export default LayoutRoutes