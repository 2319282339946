// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface DashboardState {
  dashboard: any;
  isLoading: boolean;
  weekVisitors: any[];
  inquiryData: any[];
  typeOfSpices: any[];
  productList: any[];
  allCategories: any[];
  newsData: any[];
  productRecord: number;
  inquiryRecord: number;
}

const dashboardState: DashboardState = {
  dashboard: {},
  isLoading: false,
  weekVisitors: [],
  inquiryData: [],
  productList: [],
  newsData: [],
  productRecord: 0,
  inquiryRecord: 0,
  allCategories: [],
  typeOfSpices: []
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardState,
  reducers: {
    setDashboardData(state: any, action: any) {
      state.dashboard = action.payload;
    },
    setProductList(state: any, action: any) {
      state.productList = action.payload;
    },
    setProductRecord(state: any, action: any) {
      state.productRecord = action.payload;
    },
    setInquiryRecord(state: any, action: any) {
      state.inquiryRecord = action.payload;
    },
    setNewsData(state: any, action: any) {
      state.newsData = action.payload;
    },
    setInquiryData(state: any, action: any) {
      state.inquiryData = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    setVisitorData(state: any, action: any) {
      state.visitorData = action.payload;
    },
    setAllCategories(state: any, action: any) {
      state.allCategories = action.payload;
    },
    setTypeOfSpices(state: any, action: any) {
      state.typeOfSpices = action.payload;
    },
  },
});

export const {
  setDashboardData,
  setLoading,
  setVisitorData,
  setInquiryData,
  setProductList,
  setNewsData,
  setProductRecord,
  setInquiryRecord,
  setAllCategories,
  setTypeOfSpices
}: any = DashboardSlice.actions;

export default DashboardSlice.reducer;

export const dashboard_data = (state: any) => state.dashboard.dashboard;

export const inquiry_data = (state: any) => state.dashboard.inquiryData;

export const news_list = (state: any) => state.dashboard.newsData;

export const product_list = (state: any) => state.dashboard.productList;

export const product_record_count = (state: any) =>
  state.dashboard.productRecord;

export const inquiry_record_count = (state: any) =>
  state.dashboard.inquiryRecord;

export const visitor_data = (state: any) => state.dashboard.visitorData;

export const is_loading = (state: any) => state.dashboard.isLoading;

export const type_of_spices = (state: any) => state.dashboard.typeOfSpices;

export const all_categories = (state: any) => state.dashboard.allCategories;


