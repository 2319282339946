// ** React Imports
import { useEffect, useState } from "react"

// ** Third Party Imports
import { Card, Col, Container, Form, Label, Row } from "reactstrap"
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from "formik"
import * as yup from 'yup'

// ** Component Imports
import TextField from "../../Components/TextField"
import CsFooter from "../../Components/CsFooter"
import MultiSelect from "../../Components/MultiSelect"

// ** Custom Imports
import { H3 } from "../../AbstractElements"

// ** Store Imports
import { auth_data } from "../../ReduxToolkit/Reducer/Login/LoginSlice"

// ** Helper Imports
import countries from "../../Data/countries"
import { updateUserProfile } from "../../ReduxToolkit/Reducer/Login/LoginAction"

interface FormProps {
    name: string,
    email: string,
    email2?: string,
    contact_number: string,
    contact_number2?: string,
    company_name: string,
    address: string,
    country: null | Object,
    website: string,
}

const ProfilePage = () => {

    const [selectedCountry, setSelectedCountry] = useState<any>(null)

    const loading = false
    const authData = useSelector(auth_data)
    const dispatch = useDispatch()

    const initialValue: FormProps = {
        name: '',
        email: '',
        email2: '',
        contact_number: '',
        contact_number2: '',
        address: '',
        country: null,
        company_name: '',
        website: '',
    }

    const validationSchema = yup.object({
        name: yup.string().required('Please enter your name.'),
        email: yup.string().required('Please enter your email.'),
        contact_number: yup.string()
            .matches(/^[0-9]{10}$/, "Contact number must be exactly 10 digits")
            .required("Contact number is required"),
        company_name: yup.string().required('Please enter company name.')
    })

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let passData: any = { ...values }
            if (selectedCountry) {
                const countryData = countries.find((c) => c.key === selectedCountry.value)
                passData.country = {
                    country_name: countryData?.country_name,
                    currency: countryData?.currency,
                    short_code: countryData?.key,
                    symbol: countryData?.currency_symbol,
                }
                passData.country_code = countryData?.value
            }
            if (isChanges()) {
                await updateUserProfile({ id: authData?._id, ...passData }, dispatch)
            }
        }
    })
    const isChanges = () => {
        if (!authData || !formik.values) return false;

        return (
            authData.name !== formik.values.name ||
            authData.email !== formik.values.email ||
            (authData.email2 || '') !== (formik.values.email2 || '') ||
            (authData.contact_number || '') !== (formik.values.contact_number || '') ||
            (authData.contact_number2 || '') !== (formik.values.contact_number2 || '') ||
            authData.company_name !== formik.values.company_name ||
            (authData.country?.short_code || '') !== (selectedCountry?.value || '') ||
            (authData.address || '') !== (formik.values.address || '') ||
            (authData.website || '') !== (formik.values.website || '')
        );
    };

    useEffect(() => {
        Object.keys(authData).length > 0 &&
            formik.setValues({
                ...formik.values,
                name: authData?.name,
                email: authData?.email,
                email2: authData?.email2 ?? '',
                contact_number: authData?.contact_number,
                contact_number2: authData?.contact_number2 ?? '',
                company_name: authData?.company_name,
                address: authData?.address ?? '',
                website: authData?.website ?? '',
            })
        setSelectedCountry({ label: authData?.country?.country_name, value: authData?.country?.short_code })

    }, [authData])

    return <div className="page-body">
        <Container fluid={true} className="p-3">
            <H3>User Profile</H3>

            {/* Form */}
            <Card className="p-3 mt-3">
                <Form className="theme-form">
                    <Row>
                        <Col sm={6}>
                            <TextField
                                label={"Name"}
                                boxClass="mb-3 w-100"
                                name={"name"}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                placeholder="Enter Name"
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                label={"Company Name"}
                                boxClass="mb-3 w-100"
                                name={"company_name"}
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                placeholder="Enter Company Name"
                                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                helperText={formik.touched.company_name && formik.errors.company_name}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                label={"Email"}
                                boxClass="mb-3 w-100"
                                name={"email"}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                placeholder="Enter Email"
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                label={"Second Email"}
                                boxClass="mb-3 w-100"
                                name={"email2"}
                                value={formik.values.email2}
                                onChange={formik.handleChange}
                                placeholder="Enter Second Email"
                                error={formik.touched.email2 && Boolean(formik.errors.email2)}
                                helperText={formik.touched.email2 && formik.errors.email2}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                maxLength={10}
                                type='number'
                                label={"Contact number"}
                                boxClass="mb-3 w-100"
                                name={"contact_number"}
                                value={formik.values.contact_number}
                                onChange={formik.handleChange}
                                placeholder="Enter Contact Number"
                                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                                helperText={formik.touched.contact_number && formik.errors.contact_number}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                maxLength={10}
                                type='number'
                                label={"Second Contact Number"}
                                boxClass="mb-3 w-100"
                                name={"contact_number2"}
                                value={formik.values.contact_number2}
                                onChange={formik.handleChange}
                                placeholder="Enter Second Contact Number"
                                error={formik.touched.contact_number2 && Boolean(formik.errors.contact_number2)}
                                helperText={formik.touched.contact_number2 && formik.errors.contact_number2}
                            />
                        </Col>
                        <Col sm={6}>
                            <Label className="mb-2">Country</Label>
                            <MultiSelect
                                value={selectedCountry}
                                label='Select Country'
                                onChange={(value: any) => setSelectedCountry(value)}
                                options={countries.map((con) => ({ label: con.country_name, value: con?.key }))}
                                isMulti={false}
                            />
                        </Col>
                        <Col sm={6}>
                            <TextField
                                type='url'
                                label={"Website"}
                                boxClass="mb-3 w-100"
                                name={"website"}
                                value={formik.values.website}
                                onChange={formik.handleChange}
                                placeholder="Enter Website Url"
                                error={formik.touched.website && Boolean(formik.errors.website)}
                                helperText={formik.touched.website && formik.errors.website}
                            />
                        </Col>
                        <Col sm={12}>
                            <TextField
                                type='textarea'
                                label={"Address"}
                                boxClass="mb-3 w-100"
                                name={"address"}
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                placeholder="Enter Address"
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Col>
                    </Row>
                    <CsFooter
                        type="submit"
                        color="primary"
                        label="Update"
                        isSpace={"pt-3"}
                        disabled={loading}
                        onClick={formik.handleSubmit}
                    />
                </Form>
            </Card>
        </Container>
    </div>
}

export default ProfilePage