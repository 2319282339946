// ** React Imports
import { useEffect, useState } from "react";

// ** Components Imports
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, Image, P } from "../../AbstractElements";
import TextField from "../../Components/TextField";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { changePassword } from "../../ReduxToolkit/Reducer/Login/LoginAction";

// ** Icon Imports
import { FeatherIcons } from "../../AbstractElements";

// ** Helper Imports
import { dynamicImage } from "../../Service";
import AppUtils from "../../Helper/AppUtils";
import { auth_data } from "../../ReduxToolkit/Reducer/Login/LoginSlice";

interface Change {
  new_password: string;
  confirm_password: string;
}

const   ChangePasswordPage = () => {
  // State
  const [show, setShow] = useState<{
    new: boolean;
    con: boolean;
  }>({
    new: false,
    con: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const authUser: any = useSelector(auth_data);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: Change = {
    new_password: "",
    confirm_password: "",
  };

  const schema = yup.object({
    new_password: yup
      .string()
      .required("Please enter password")
      .min(8, "Password must be 8 characters")
      .notOneOf(
        [yup.ref("old_password")],
        "New password cannot be the same as the old password"
      ),
    confirm_password: yup
      .string()
      .required("Please enter confirm password")
      .min(8, "Password must be 8 characters")
      .oneOf(
        [yup.ref("new_password")],
        "Confirm password must match the new password"
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: Change) => {
      setLoading(true);
      const res = await changePassword(
        { ...value, id: authUser?._id },
        dispatch
      );
      if (res?.status) {
        formik.resetForm();
        navigate(`/login`);
        setLoading(false);
      } else {
        if (
          AppUtils.checkValue(res?.message) &&
          Array.isArray(res?.message) &&
          res?.message?.length > 0
        ) {
          res?.message?.map((item: any) => {
            formik.setFieldError(item?.field, item?.message);
          });
        }
        setLoading(false);
      }
    },
  });

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div className="login-main p-4">
              <Form className="theme-form" onSubmit={formik.handleSubmit}>
                <Image
                  className="img-fluid mx-auto mb-3"
                  src={dynamicImage("logo/LOGO.svg")}
                  alt="Vora Spices"
                />
                <H4>Change Password</H4>
                <P className="mt-3">Enter password to change.</P>
                <TextField
                  label={"New Password"}
                  boxClass="mb-3"
                  name={"new_password"}
                  type={show.new ? "text" : "password"}
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  placeholder="Enter New Password"
                  error={
                    formik.touched.new_password &&
                    Boolean(formik.errors.new_password)
                  }
                  helperText={
                    formik.touched.new_password && formik.errors.new_password
                  }
                  boxStyle={{ position: "relative" }}
                  iconJsx={
                    <div
                      style={{
                        position: "absolute",
                        right: "0.625rem",
                        top: "2.1rem",
                        cursor: "pointer",
                      }}
                    >
                      <FeatherIcons
                        onClick={() =>
                          setShow((prev: any) => ({ ...prev, new: !show.new }))
                        }
                        iconName={show.new ? "Eye" : "EyeOff"}
                      />
                    </div>
                  }
                />
                <TextField
                  label={"Confirm Password"}
                  boxClass="mb-3"
                  name={"confirm_password"}
                  type={show.con ? "text" : "password"}
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  placeholder="Enter Confirm Password"
                  error={
                    formik.touched.confirm_password &&
                    Boolean(formik.errors.confirm_password)
                  }
                  helperText={
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                  }
                  boxStyle={{ position: "relative" }}
                  iconJsx={
                    <div
                      style={{
                        position: "absolute",
                        right: "0.625rem",
                        top: "2.1rem",
                        cursor: "pointer",
                      }}
                    >
                      <FeatherIcons
                        onClick={() =>
                          setShow((prev: any) => ({ ...prev, con: !show.con }))
                        }
                        iconName={show.con ? "Eye" : "EyeOff"}
                      />
                    </div>
                  }
                />
                <FormGroup className="mb-0 form-sub-title position-relative">
                  <div className="text-start mt-3">
                    <Btn color="primary" className="w-100" disabled={loading}>
                      Change Password
                    </Btn>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePasswordPage;
