// ** Third Party Imports
import axios from "axios";
import { toast } from "react-toastify";

// ** Helper Imports
import AppUtils from "../AppUtils";
import UrlHelper from "../Url";

const apiCall: any = async (data: any) => {
  let response: any;
  const localstoreData: any = AppUtils.getLocalStorage("VORA_CUSTOMER");

  const header = {
    Authorization: `Bearer ${localstoreData?.token ?? ""}`,
    ...(data.isMultipart && { "Content-Type": "multipart/form-data" }),
  };

  try {
    response = await axios({
      method: data.method,
      url: data?.isAdmin
        ? `${UrlHelper.serverUrl}/api/${data.url}`
        : data?.isCustom
          ? data.url
          : `${UrlHelper.serverUrl}/customer/api/${data.url}`,
      headers: header,
      data: data.data,
    });
  } catch (e: any) {
    response = e;
  }

  if (response?.status === 200 && response?.data?.status) {
    if (!data?.isHideTostMsg) toast.success(response.data.message);

    return response.data;
  } else {
    toast.error(
      response?.response?.data?.message &&
        response?.response?.data?.message !== undefined
        ? response?.response?.data?.message
        : "Something went wrong !"
    );
  }

  if (response?.response?.status === 403) {
    AppUtils.removeLocalStorageItem('VORA_CUSTOMER')
    AppUtils.removeLocalStorageItem('CURR_DATE')
    AppUtils.removeLocalStorageItem('TODAY_NEWS')
    window.location.href = '/login'
  }

  if (data.isLogin) return response?.data;
  else return response?.response?.data;
};

export const customApiCall: any = async (data: any) => {
  const response = await axios({
    method: data?.method,
    url: data?.url,
    headers: data?.headers,
  });

  return response;
};

export default apiCall;
