// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Components Imports
import { Info, MessageSquare } from "react-feather";

// ** Third party Imports
import moment from "moment";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import Chat from "../Chat";
import CsTable from "../../../Components/CsTable";
import QuotationInfo from "../InfoDialog";

// ** Store Imports
import {
  all_quotes_count,
  all_quotes_data,
  curr_quote,
  is_loading,
  quote_filter,
  setAllQoutesList,
  setCurrQuote,
  updateUnreadCount,
} from "../../../ReduxToolkit/Reducer/Quotes";
import {
  fetchQuotatopnList,
  seenResponse,
} from "../../../ReduxToolkit/Reducer/Quotes/QuotesAction";
import { updateMessageStatus } from "../../../ReduxToolkit/Reducer/Chat/ChatAction";
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Config Imports
import socket from "../../../Config/socket";

// ** Helpers Imports
import AppUtils from "../../../Helper/AppUtils";
import { getNotificationList } from "../../../ReduxToolkit/Reducer/Notification/NotificationAction";

const AllQuoteList = () => {
  // States
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isChat, setIsChat] = useState<boolean>(false);
  const [isResp, setIsResp] = useState<any>({});

  // Hooks
  const dispatch = useDispatch();
  const allQuotes = useSelector(all_quotes_data);
  const count = useSelector(all_quotes_count);
  const filter = useSelector(quote_filter);
  const loading = useSelector(is_loading);
  const currQuote = useSelector(curr_quote);
  const authUser = useSelector(auth_data);

  const columns = [
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Sr no.</span>,
      width: "80px",
      sortable: false,
      cell: (item: any, index: number) => {
        return <>
          <div
            style={{
              height: "50%",
              width: "0.1rem",
              backgroundColor: item?.is_seen ? "green" : "red",
              borderRadius: "1rem",
              marginRight: "1rem",
            }}
          />
          {currentPage > 1 ? (index + 1) + ((currentPage - 1) * 10) : index + 1}
        </>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Company Name
        </span>
      ),
      minWidth: "300px",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.company_name ? item?.company_name : "N/A"}
          </>
        );
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>Delivery</span>
      ),
      minWidth: "150px",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.delivery ? item?.delivery : "N/A"}</>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Quotation Date
        </span>
      ),
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.createdAt
              ? moment(item?.createdAt).format("DD MMM YYYY hh:mm A")
              : "N/A"}
          </>
        );
      },
    },
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Status</span>,
      minWidth: "150px",
      sortable: false,
      cell: (item: any) => {
        return (
          <Badge
            className="p-2"
            style={{ width: "5.625rem" }}
            color={
              item?.status === 1
                ? "primary"
                : item?.status === 2
                  ? "secondary"
                  : ""
            }
          >
            {item?.status === 1
              ? "Pending"
              : item?.status === 2
                ? "Responded"
                : ""}
          </Badge>
        );
      },
    },
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Action</span>,
      minWidth: "120px",
      cell: (item: any) => (
        <>
          <div>
            <Info
              id={`send-${item?._id}`}
              className="me-2"
              style={{
                cursor: AppUtils.checkValue(item?.response)
                  ? "pointer"
                  : "default",
                opacity: AppUtils.checkValue(item?.response) ? 1 : 0.5,
              }}
              onClick={() => {
                AppUtils.checkValue(item?.response) && setIsResp(item);
              }}
            />
            <UncontrolledTooltip placement="bottom" target={`send-${item._id}`}>
              Information
            </UncontrolledTooltip>
          </div>
          <div
            style={{ position: "relative", display: "inline-block" }}
            onClick={() => {
              setIsChat(true);
              dispatch(setCurrQuote({ ...item, unreadCount: 0 }));
            }}
          >
            <MessageSquare
              id={`chat-${item._id}`}
              style={{
                cursor:
                  AppUtils.checkValue(item?.company_name) &&
                    AppUtils.checkValue(item?.customer_id)
                    ? "pointer"
                    : "default",
                opacity:
                  AppUtils.checkValue(item?.company_name) &&
                    AppUtils.checkValue(item?.customer_id)
                    ? 1
                    : 0.5,
              }}
            />
            {item?.unreadCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  backgroundColor: "#006666",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                  lineHeight: "12px",
                }}
              >
                {item?.unreadCount}
              </span>
            )}
            <UncontrolledTooltip placement="bottom" target={`chat-${item._id}`}>
              Chat
            </UncontrolledTooltip>
          </div>
        </>
      ),
    },
  ];

  const getData = useCallback(async () => {
    await fetchQuotatopnList(
      {
        page: currentPage,
        limit: 10,
        search: filter?.search,
        status: AppUtils.checkValue(filter?.status)
          ? Array.isArray(filter?.status) &&
          filter?.status?.map((item: any) => parseInt(item?.value))
          : [],
        startDate: filter?.startDate ? filter?.startDate : "",
        endDate: filter?.endDate ? filter?.endDate : "",
      },
      dispatch
    );
  }, [currentPage, filter?.status, filter?.startDate, filter?.endDate]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    Array.isArray(allQuotes) &&
      allQuotes?.length > 0 &&
      socket.on("incomingChatMessage", (value: any) => {
        const message = JSON.parse(value);
        dispatch(
          updateUnreadCount({
            id: message?.quotation_id,
            unreadCount: message.unreadCount,
          })
        );
      });
  }, [allQuotes?.length]);

  useEffect(() => {
    Array.isArray(allQuotes) &&
      allQuotes?.length > 0 &&
      socket.on("clearChatNotify", (value: any) => {
        const message = JSON.parse(value);
        dispatch(
          updateUnreadCount({
            id: message?.quotation_id,
            count: message?.unreadCount,
          })
        );
      });
  }, [allQuotes?.length]);

  return (
    <>
      <div className="table-responsive">
        <CsTable
          columns={columns}
          data={allQuotes ?? []}
          onRowClicked={(row: any) =>
            !row?.is_seen &&
            row?.status === 2 &&
            seenResponse({ id: row?._id }, dispatch)
          }
          onChangePage={(pageNumber: number) => setCurrentPage(pageNumber)}
          progressPending={loading}
          paginationTotalRows={count ?? 10}
        />
      </div>

      {isChat && (
        <Chat
          isChat={isChat}
          toggle={async () => {
            Array.isArray(allQuotes) &&
              allQuotes?.length > 0 &&
              dispatch(
                setAllQoutesList(
                  allQuotes?.map((item: any) =>
                    item?._id === currQuote?._id &&
                      item?.customer_id === authUser?._id
                      ? { ...item, unreadCount: 0 }
                      : item
                  )
                )
              );
            await updateMessageStatus(
              {
                customer_id: authUser?._id,
                quotation_id: currQuote?._id,
              },
              dispatch
            );
            await getNotificationList({ sent_by: "ADMIN" }, dispatch)
            setIsChat(false);
          }}
        />
      )}

      {Object?.keys(isResp)?.length > 0 && (
        <QuotationInfo
          isOpen={Object?.keys(isResp)?.length > 0}
          toggle={() => setIsResp({})}
          data={isResp}
          openChat={() => {
            setIsResp({}); setIsChat(true);
            dispatch(setCurrQuote({ ...isResp, unreadCount: 0 }));
          }}
        />
      )}
    </>
  );
};

export default AllQuoteList;
