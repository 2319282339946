// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface InitialAuthState {
    notificationList: any[];
    notificationCount: number;
    isLoading: boolean;
}

const initialAuthState: InitialAuthState = {
    notificationList: [],
    notificationCount: 0,
    isLoading: false,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState: initialAuthState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setNotificationList(state: any, action: any) {
            state.notificationList = action.payload;
        },
        setNotificationCount(state: any, action: any) {
            state.notificationCount = action.payload;
        },
        updateNotification(state: any, action: any) {
            const updatedList = state.notificationList.filter((n: any) => n?._id !== action?.payload);
            state.notificationList = updatedList;
            state.notificationCount = updatedList.length;
        },
    },
});

export const { setLoading, setNotificationCount, setNotificationList, updateNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

export const notification_list = (state: any) => state.notification.notificationList;

export const notification_count = (state: any) => state.notification.notificationCount;

export const is_loading = (state: any) => state.notification.isLoading;
