// ** Third Party Imports
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { useSelector } from "react-redux";

const MultiSelect = (props: any) => {
  // Props
  const { options, onChange, value, isMulti } = props;

  // Hooks
  const { darkMode } = useSelector((state: any) => state.layout);

  const loadOptions: LoadOptions<any, any, any> = (inputValue: any) => {
    return new Promise((resolve) => {
      resolve({
        options: options.filter((option: any) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        ),
        hasMore: false,
      });
    });
  };

  return (
    <div
      className="d-flex flex-column position-relative"
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="d-flex position-relative w-100"
        style={{ height: "100%", width: "100%" }}
      >
        <AsyncPaginate
          loadOptions={loadOptions}
          value={value}
          isMulti={isMulti}
          onChange={onChange}
          placeholder={props.label ? props.label : "Select Items"}
          styles={{
            container: (provided) => ({
              ...provided,
              width: "100%",
              height: "100%",
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: darkMode ? "#22242b" : "white",
              color: darkMode ? "#86909c" : "black",
              borderColor: darkMode ? "rgba(255, 255, 255, 0.1)" : "#dee2e6",
              borderRadius: "0.375rem",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: darkMode ? "#86909c" : "black",
            }),
            input: (provided) => ({
              ...provided,
              color: darkMode ? "#86909c" : "black",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: darkMode ? "#86909c" : "black",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: darkMode ? "#22242b" : "white",
              zIndex: 9999,
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? "lightgrey"
                : darkMode
                ? "#22242b"
                : "white",
              color: darkMode ? "#86909c" : "black",
              "&:hover": {
                backgroundColor: "lightgrey",
              },
            }),
          }}
        />
      </div>
    </div>
  );
};

export default MultiSelect;
