// ** React Imports
import { useCallback } from "react";

// ** Component Imports
import { Input } from "reactstrap";

// ** Third Party Imports
import debounce from "lodash.debounce";

const CsSearch = (props: any) => {
  // ** Props
  const {
    handleChange,
    placeholder,
    value,
    setState,
    loading,
    boxClass,
    name,
  } = props;

  const debouncedResults = useCallback(
    debounce((e: any) => handleChange(e.target.value), 1000),
    [handleChange]
  );

  return (
    <>
      <div className={boxClass}>
        <Input
          type="text"
          disabled={loading}
          name={name}
          value={value}
          placeholder={placeholder ?? ""}
          onChange={(e) => {
            const val = e.target.value;
            if (!/^\s/.test(val)) {
              debouncedResults(e);
              setState(e.target.value);
            }
          }}
        />
      </div>
    </>
  );
};

export default CsSearch;
