import { Navigate, Outlet } from "react-router-dom";

// ** Helper Imports
import AppUtils from "../Helper/AppUtils";

const PrivateRoute = () => {
  const login = AppUtils.getLocalStorage("VORA_CUSTOMER")?.token
    ? AppUtils.getLocalStorage("VORA_CUSTOMER")?.token
    : false;

  return login !== false ? (
    <Outlet />
  ) : (
    <Navigate to={`${process.env.PUBLIC_URL}/login`} />
  );
};

export default PrivateRoute;
