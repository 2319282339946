const Consts = {
  passWordRegx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
  emailRegex:
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
  nameRegex: /^[a-zA-Z]+[ ]{0,1}[a-zA-Z ]*$/,
  alphabetRegex: /[^ a-zA-Z]/g,
  numberRegex: /^(?!(0))[0-9]+$/,
  descRegex: /[^ a-zA-Z0-9!@#$%^&*()_+{}]/g,
  spaceRegex: /^\s/,
  removeSpaceRegex: /&nbsp;/g,
  passMatchRegex: /[^a-zA-Z0-9@!#$&*>]/g,
  isImgRegex: /\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
  isVideoRegex: /\.(mp4|avi|mov|mkv|webm)$/,
  httpOrHttpsPattern: /^(http:\/\/|https:\/\/)/,
  stringRegex: /[^ a-zA-Z0-9]/g,
  noRegex: /[^0-9]/g,
  mobileNoRegex: /^(?!0+$)[0-9\(\)-]{10,}$/,
  URLRegex: /^(ftp|http|https):\/\/[^ "]+$/,
  newsAPIKey: "80c4fdab15fc4694a20bd48ccd0de926",
};

export default Consts;
