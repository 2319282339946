// ** Components Imports
import { Container } from "reactstrap";
import { H3 } from "../../AbstractElements";

// ** Custom Imports
import ProductDataTable from "../Products/ProductDataTable";
import InquiryDataTable from "./InquiryDataTable";
import NewsBox from "./NewsBox";

const Dashboard = () => {
  return (
    <div className="page-body">
      <Container fluid={true} className="p-3">
        <H3 className="mb-3">Dashboard</H3>
        <NewsBox />
        <InquiryDataTable />
        {/* <ProductDataTable /> */}
      </Container>
    </div>
  );
};

export default Dashboard;
