import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";

import LoginPage from "../Pages/Login";
import ChangePasswordPage from "../Pages/ChangePassword";

const RouterData = () => {
  const login = localStorage.getItem("VORA_CUSTOMER");

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        {login ? (
          <>
            <Route
              path={`${process.env.PUBLIC_URL}` || "/"}
              element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}
            />
          </>
        ) : (
          ""
        )}
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Route>
        <Route path={"/"} element={<PrivateRoute />}>
          <Route path={`/change-password`} element={<ChangePasswordPage />} />
        </Route>
        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          element={<LoginPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
