import { Fragment, useState } from "react";
import { H6, LI } from "../../AbstractElements";
import { MenuList } from "../../Data/Sidebar";
import MenuLists from "./MenuLists";

import "../../index.scss";

interface MenuItem {
  title: string;
  lanClass?: string;
  menucontent?: string;
  Items?: MenuItem[];
  id?: number;
  icon?: string;
  type?: string;
  active?: boolean;
  children?: MenuItem[];
  path?: string;
  mainTitle?: string;
  bookmark?: boolean;
}

const SidebarMenuList = () => {
  const [activeMenu, setActiveMenu] = useState<string[]>([]);

  return (
    <>
      {MenuList &&
        MenuList.map((mainMenu: MenuItem, index: any) => (
          <Fragment key={index}>
            <LI className={`sidebar-main-title`}>
              <div>
                <H6 className={mainMenu.lanClass && mainMenu.lanClass}>
                  {mainMenu.title}
                </H6>
              </div>
            </LI>
            <MenuLists
              menu={mainMenu.Items}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              level={0}
            />
          </Fragment>
        ))}
    </>
  );
};

export default SidebarMenuList;
