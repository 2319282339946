import { FeatherIcons, LI } from "../../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { setDarkMode } from "../../../../ReduxToolkit/Reducer/Layout/LayoutSlice";

const DarkMode = () => {
  const { darkMode } = useSelector((state: any) => state.layout);
  const dispatch = useDispatch();
  const handleDarkMode = () => {
    dispatch(setDarkMode(!darkMode));
  };
  return (
    <LI onClick={handleDarkMode}>
      <div className={`mode ${darkMode ? "active" : ""}`}>
        <FeatherIcons className="moon" iconName="Moon" />
      </div>
    </LI>
  );
};

export default DarkMode;
