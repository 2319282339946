// ** Slice Imports
import {
  setLoading,
  setAllQoutesList,
  setAllQoutesRecords,
  createQuote,
  setSeen,
  setAllCategories,
  setAllUnits,
} from ".";
import AppUtils from "../../../Helper/AppUtils";

// ** Helper Imports
import apiCall from "../../../Helper/Service";

export const fetchQuotatopnList = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "quote/getCustomersQuotationList",
    data: passData,
    method: "POST",
    isHideTostMsg: true,
  });

  if (res?.status) {
    dispatch(setAllQoutesList(res?.data));
    dispatch(setAllQoutesRecords(res?.totalRecords));
  }
  dispatch(setLoading(false));

  return res;
};

export const addQuotes = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "quote/create",
    data: passData,
    method: "POST",
  });

  res?.status && dispatch(createQuote(res?.data));
  dispatch(setLoading(false));

  return res;
};

export const seenResponse = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "quote/seenResponse",
    data: passData,
    method: "POST",
  });

  res?.status && dispatch(setSeen(passData));
  dispatch(setLoading(false));

  return res;
};

export const fetchAllCategories = async (dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "category/categoryDropDown",
    data: {},
    method: "POST",
    isHideTostMsg: true,
    isAdmin: true,
  });

  const newArr =
    AppUtils.checkValue(res?.data) &&
    Array?.isArray(res?.data) &&
    res?.data?.length > 0
      ? res?.data?.map((item: any) => {
          return { label: item?.category_name, value: item?._id };
        })
      : [];

  if (res?.status) {
    dispatch(setAllCategories(newArr));
    dispatch(setLoading(false));
  }

  return res;
};

export const fetchAllUnits = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "unit/unitList",
    data: passData,
    method: "POST",
    isHideTostMsg: true,
    isAdmin: true,
  });

  const newArr =
    AppUtils.checkValue(res?.data) &&
    Array?.isArray(res?.data) &&
    res?.data?.length > 0
      ? res?.data?.map((item: any) => {
          return { label: item?.unit_name, value: item?._id };
        })
      : [];

  if (res?.status) {
    dispatch(setAllUnits(newArr));
    dispatch(setLoading(false));
  }

  return res;
};
