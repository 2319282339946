import { createSlice } from "@reduxjs/toolkit";

const saveDarkMode: any = localStorage.getItem('darkMode') === "true"

if (saveDarkMode) {
  document.body.classList.add("dark-only");
} else {
  document.body.classList.add("light");
}

const initialState = {
  flip: false,
  darkMode: saveDarkMode,
  toggleSidebar: false,
  layoutName: "",
  responsiveSearch: false,
  scroll: false,
  margin: 0,
};

const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    setFlip: (state) => {
      state.flip = !state.flip;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
      localStorage.setItem('darkMode', state.darkMode)
      if (state.darkMode) {
        document.body.classList.remove("light");
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.remove("dark-only");
        document.body.classList.add("light");
      }
    },
    setToggleSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    },
    handleResponsiveToggle: (state) => {
      state.toggleSidebar = true;
    },
    setResponsiveSearch: (state) => {
      state.responsiveSearch = !state.responsiveSearch;
    },
    setScrollMenu: (state, action) => {
      state.scroll = action.payload;
    },
    scrollToLeft: (state) => {
      state.margin += 500;
    },
    scrollToRight: (state) => {
      state.margin -= 500;
    },
  },
});

export const {
  setFlip,
  setDarkMode,
  setToggleSidebar,
  handleResponsiveToggle,
  setResponsiveSearch,
  setScrollMenu,
  scrollToLeft,
  scrollToRight,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
