// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Components Imports
import { Card } from "reactstrap";
import { H3 } from "../../../AbstractElements";

// ** Third party Imports
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";

// ** Custom Imports
import CsTable from "../../../Components/CsTable";

// ** Store Imports
import {
  inquiry_data,
  inquiry_record_count,
} from "../../../ReduxToolkit/Reducer/Dashboard";
import { fetchInquiryData } from "../../../ReduxToolkit/Reducer/Dashboard/dashboardAction";

const InquiryDataTable = () => {
  // States
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false)

  // Hooks
  const dispatch = useDispatch();
  const inquiryData = useSelector(inquiry_data);
  const count = useSelector(inquiry_record_count);

  const columns = [
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Sr no.</span>,
      width: "80px",
      sortable: false,
      cell: (item: any, index: number) => {
        return <>
          {currentPage > 1 ? (index + 1) + ((currentPage - 1) * 10) : index + 1}
        </>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Product Name
        </span>
      ),
      minWidth: "300px",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.product_id?.product_name
              ? item?.product_id?.product_name
              : "N/A"}
          </>
        );
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Date of Inquiry
        </span>
      ),
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.createdAt
              ? moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")
              : "N/A"}
          </>
        );
      },
    },
  ];

  const getData = useCallback(async () => {
    setLoading(true)
    await fetchInquiryData({ startToken: currentPage, endToken: 10 }, dispatch);
    setLoading(false)
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Card className="px-3 pb-3">
        <div className="d-flex  align-items-center py-3 border-bottom">
          <H3>Inquiry History</H3>
        </div>
        <CsTable
          columns={columns}
          data={inquiryData ?? []}
          paginationTotalRows={count ?? 10}
          onChangePage={(pageNumber: number) => setCurrentPage(pageNumber)}
          progressPending={loading}
        />
      </Card>
    </>
  );
};

export default InquiryDataTable;
