import { Link } from "react-router-dom";
import { dynamicImage } from "../../../Service";
import { FeatherIcons, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { setToggleSidebar } from "../../../ReduxToolkit/Reducer/Layout/LayoutSlice";

const HeaderLogo = () => {
  const dispatch = useDispatch();
  const { toggleSidebar } = useSelector((state: any) => state.layout);
  return (
    <div className="header-logo-wrapper col-auto p-0">
      <div className="logo-wrapper">
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          <Image
            className="img-fluid"
            src={dynamicImage("logo/LOGO.svg")}
            alt="logoLight"
          />
        </Link>
      </div>
      <div className="toggle-sidebar">
        <FeatherIcons
          className="status_toggle middle sidebar-toggle"
          iconName="AlignCenter"
          onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}
        />
      </div>
    </div>
  );
};

export default HeaderLogo;
