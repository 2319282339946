import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { LI, SVG, UL } from "../../AbstractElements";

import "../../index.scss";
interface SubChildrenType {
  title: string;
  type: string;
  path: string;
}

interface SidebarChildrenType {
  path?: string;
  title: string;
  type: string;
  lanClass?: string;
  children?: SubChildrenType[];
}

interface SidebarItemTypes {
  item: {
    id?: number;
    title?: string | undefined;
    icon?: string;
    type?: string;
    active?: boolean;
    path?: string;
    children?: SidebarChildrenType[];
    lanClass?: string;
  };
}

interface MenuItem {
  title: string;
  lanClass?: string;
  menucontent?: string;
  Items?: MenuItem[];
  id?: number;
  icon?: string;
  type?: string;
  active?: boolean;
  children?: MenuItem[];
  path?: string;
  mainTitle?: string;
  bookmark?: boolean;
}

interface MenuListType {
  menu?: MenuItem[] | undefined;
  level: number;
  className?: string;
  setActiveMenu: Function;
  activeMenu: unknown[];
}

const MenuLists: React.FC<MenuListType> = ({
  menu,
  setActiveMenu,
  activeMenu,
  level,
  className,
}) => {
  const { sidebarIconType } = useSelector(
    (state: any) => state.themeCustomizer
  );
  const location = useLocation();
  const ActiveNavLinkUrl = (path?: string) => {
    return location.pathname === path ? true : "";
  };

  const shouldSetActive = ({ item }: SidebarItemTypes) => {
    var returnValue = false;
    if (item?.path === location.pathname) returnValue = true;
    if (!returnValue && item?.children) {
      item?.children.every((subItem) => {
        returnValue = shouldSetActive({ item: subItem });
        return !returnValue;
      });
    }
    return returnValue;
  };

  const handleClick = (item: string) => {
    const temp = activeMenu;
    temp[level] = item !== temp[level] ? item : "";
    setActiveMenu([...temp]);
  };

  useEffect(() => {
    menu?.forEach((item: any) => {
      let gotValue = shouldSetActive({ item });
      if (gotValue) {
        let temp = [...activeMenu];
        temp[level] = item.title;
        setActiveMenu(temp);
      }
    });
  }, [location.pathname]);

  return (
    <>
      {menu?.map((item, index) => {
        return (
          <LI
            key={index}
            className={`${level === 0 ? "sidebar-list" : ""}  
          ${
            (item.children
              ? item.children
                  .map((innerItem) => ActiveNavLinkUrl(innerItem.path))
                  .includes(true)
              : ActiveNavLinkUrl(item.path)) || activeMenu[level] === item.title
              ? "active"
              : ""
          } `}
          >
            <Link
              className={`${
                !className && level !== 2 ? "sidebar-link sidebar-title" : ""
              } 
            ${
              (item.children
                ? item.children
                    .map((innerItem) => ActiveNavLinkUrl(innerItem.path))
                    .includes(true)
                : ActiveNavLinkUrl(item.path)) ||
              activeMenu[level] === item.title
                ? "active"
                : ""
            }`}
              to={item.path ? item.path : "#"}
              onClick={() => handleClick(item.title)}
            >
              {item.icon && (
                <>
                  {item?.title === "Settings" ? (
                    <img
                      src="/assets/svg/Settings.svg"
                      style={{
                        width: "1rem !important",
                        height: "1rem",
                        marginRight: "0.8rem",
                        marginLeft: "0rem",
                      }}
                      id="setting_img"
                    />
                  ) : (
                    <SVG
                      className={`${sidebarIconType}-icon`}
                      iconId={`${sidebarIconType}-${item.icon}`}
                    />
                  )}
                </>
              )}
              <span className={item.lanClass && item.lanClass}>
                {item.title}
              </span>
              {item.children &&
                (activeMenu[level] === item.title ? (
                  <div className="according-menu">
                    <i className="fa fa-angle-down" />
                  </div>
                ) : (
                  <div className="according-menu">
                    <i className="fa fa-angle-right" />
                  </div>
                ))}
            </Link>
            {item.children && (
              <UL
                className={`simple-list ${
                  level !== 0
                    ? "nav-sub-childmenu submenu-content"
                    : "sidebar-submenu "
                }`}
                style={{
                  display: `${
                    (item.children
                      ? item.children
                          .map((innerItem) => ActiveNavLinkUrl(innerItem.path))
                          .includes(true)
                      : ActiveNavLinkUrl(item.path)) ||
                    activeMenu[level] === item.title
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                <MenuLists
                  menu={item.children}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  level={level + 1}
                  className="sidebar-submenu"
                />
              </UL>
            )}
          </LI>
        );
      })}
    </>
  );
};

export default MenuLists;
