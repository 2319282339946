// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Component Imports
import { Card, Container } from "reactstrap";
import { H3 } from "../../AbstractElements";

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// ** Custom Imports
import CsTable from "../../Components/CsTable";

// ** Store Imports
import {
  is_loading,
  login_history,
  login_history_record,
} from "../../ReduxToolkit/Reducer/Logs";
import { fetchLoginHistory } from "../../ReduxToolkit/Reducer/Logs/logsAction";

// ** Helper Imports
import AppUtils from "../../Helper/AppUtils";

const LoginLogs = () => {
  // State
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Hooks
  const dispatch = useDispatch();
  const loading = useSelector(is_loading);
  const loginHistory = useSelector(login_history);
  const count = useSelector(login_history_record) ?? 0;
  const data = AppUtils.getLocalStorage("VORA_CUSTOMER");

  const columns = [
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Sr no.</span>,
      width: "80px",
      sortable: false,
      cell: (item: any, index: number) => {
        return <>
          {currentPage > 1 ? (index + 1) + ((currentPage - 1) * 10) : index + 1}
        </>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>Login Time</span>
      ),
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.login_time
              ? moment(item?.login_time).format("DD-MM-YYYY hh:mm A")
              : "N/A"}
          </>
        );
      },
    },
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Device</span>,
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.device_type ? item?.device_type : "N/A"}</>;
      },
    },
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Panel</span>,
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.panel ? `VORA ${item?.panel}` : "N/A"}</>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>IP Address</span>
      ),
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.ip_address ? item?.ip_address : "N/A"}</>;
      },
    },
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>OS</span>,
      minWidth: "200px",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.operating_system ? item?.operating_system : "N/A"}</>;
      },
    },
  ];

  const getData = useCallback(async () => {
    await fetchLoginHistory({ customer_id: data?._id, startToken: currentPage, endToken: 10 }, dispatch);
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="page-body">
      <Container fluid className="p-3">
        <Card className="p-3">
          <div className="pb-3 border-bottom">
            <H3>Login Logs</H3>
          </div>
          <CsTable
            columns={columns}
            data={loginHistory ?? []}
            paginationTotalRows={count ?? 10}
            onChangePage={(pageNumber: number) => setCurrentPage(pageNumber)}
            progressPending={loading}
          />
        </Card>
      </Container>
    </div>
  );
};

export default LoginLogs;
