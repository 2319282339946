// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Components Imports
import { H3, P } from "../../../AbstractElements";
import { Card, Col, Row } from "reactstrap";

// ** Third party Imports
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imports
import CsSearch from "../../../Components/CsSearch";
import CsTable from "../../../Components/CsTable";

// ** Store Imports
import {
  product_list,
  is_loading,
  product_record_count,
  all_categories,
  type_of_spices,
} from "../../../ReduxToolkit/Reducer/Dashboard";
import { fetchAllCategoryList, fetchProductList, fetchTypeOfSpices } from "../../../ReduxToolkit/Reducer/Dashboard/dashboardAction";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";
import { useWindowResize } from "../../../hooks";
import MultiSelect from "../../../Components/MultiSelect";

const ProductDataTable = () => {
  // States
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<any>("");
  const [val, setVal] = useState<any>("");
  const [selectedType, setSelectedType] = useState<any>(null)
  const [selectedCat, setSelectedCat] = useState<any>(null)
  const [ddOption, setDDOption] = useState<any[]>([])
  const [categories, setCetagories] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)


  // Hooks
  const dispatch = useDispatch();
  const productList = useSelector(product_list);
  const count = useSelector(product_record_count);
  const typeOfSpices = useSelector(type_of_spices);
  const cetagoryList = useSelector(all_categories)
  const data = AppUtils.getLocalStorage("VORA_CUSTOMER");
  const { width } = useWindowResize()

  const columns = [
    {
      name: <span style={{ fontSize: "1rem", fontWeight: "600" }}>Sr no.</span>,
      width: "80px",
      sortable: false,
      cell: (item: any, index: number) => {
        return <>
          {currentPage > 1 ? (index + 1) + ((currentPage - 1) * 10) : index + 1}
        </>;
      },
    },
    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Product Name
        </span>
      ),
      minWidth: "auto",
      maxWidth: "60%",
      sortable: false,
      cell: (item: any) => {
        return <>{item?.product_name ? item?.product_name : "N/A"}</>;
      },
    },

    {
      name: (
        <span style={{ fontSize: "1rem", fontWeight: "600" }}>
          Price in USD
        </span>
      ),
      minWidth: "20%",
      sortable: false,
      cell: (item: any) => {
        return (
          <>
            {item?.product_price
              ? `$ ${Number(item?.product_price).toFixed(2).split('.')[1] === '00'
                ? Number(item?.product_price).toFixed(0)
                : Number(item?.product_price).toFixed(2)}*`
              : "0"}
          </>
        );
      },
    },

    // {
    //   name: (
    //     <span style={{ fontSize: "1rem", fontWeight: "600" }}>
    //       Price (Customer Currency)
    //     </span>
    //   ),
    //   minWidth: "20%",
    //   sortable: false,
    //   cell: (item: any) => {
    //     return (
    //       <>
    //         {item?.converted_price
    //           ? `${data?.country?.symbol} ${item?.converted_price.toFixed(0)}/-`
    //           : "0"}
    //       </>
    //     );
    //   },
    // },
  ];

  const getProduct = useCallback(async () => {
    setLoading(true)

    await fetchProductList({
      search: search,
      startToken: currentPage,
      endToken: 10,
      typeOfSpices: selectedType?.value === 'all' ? [] : [selectedType?.value],
      categoryId: selectedCat?.value === 'all' ? [] : [selectedCat?.value],
    },
      dispatch
    );
    setLoading(false)
  }, [search, currentPage, selectedType, selectedCat])


  useEffect(() => { getProduct() }, [getProduct])


  useEffect(() => {
    if (typeOfSpices?.length > 0 && ddOption?.length === 0) {
      setDDOption((prev: any[]) => ([...prev, { label: "Select All", value: 'all' }]))
      setDDOption((prev: any[]) => ([...prev, ...typeOfSpices.map((type: any) => ({ label: type?.type_name, value: type?._id }))]))
    }
  }, [typeOfSpices?.length])


  useEffect(() => {
    if (cetagoryList?.length > 0 && categories?.length === 0) {
      setCetagories((prev: any[]) => ([...prev, { label: "Select All", value: 'all' }]))
      setCetagories((prev: any[]) => ([...prev, ...cetagoryList.map((type: any) => ({ label: type?.category_name, value: type?._id }))]))
    }
  }, [cetagoryList?.length])

  useEffect(() => {
    (!typeOfSpices || typeOfSpices.length === 0) && fetchTypeOfSpices(dispatch);
  }, []);

  useEffect(() => {
    (!cetagoryList || cetagoryList.length === 0) && fetchAllCategoryList({ startToken: 1, search: '', endToken: 10 }, dispatch)
  }, []);

  return (
    <>
      <Card className="px-3 pb-3">
        <Row className="align-items-center pt-3 gap-3 gap-md-0">
          <Col md={3}>
            <H3>Product List</H3>
          </Col>
          <Col md={9}>
            <Row className="gap-3 gap-sm-0" >
              <Col sm={4}>
                <CsSearch
                  boxClass={`${width > 570 ? "w-fit" : "w-100"} ms-auto mt-0`}
                  name="Search Product"
                  placeholder="Search Product"
                  value={val}
                  setState={setVal}
                  loading={false}
                  handleChange={(val: any) => setSearch(val)}
                /></Col>
              <Col sm={4}>
                <MultiSelect
                  value={selectedType}
                  onChange={(opt: any) => setSelectedType(opt)}
                  options={ddOption}
                  isMulti={false}
                  label="Type of spices"
                />
              </Col>
              <Col sm={4}>
                <MultiSelect
                  value={selectedCat}
                  onChange={(opt: any) => setSelectedCat(opt)}
                  options={categories}
                  isMulti={false}
                  label="Select Cetagory"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <CsTable
          columns={columns}
          data={productList.filter((product: any) => product.is_publish === true) ?? []}
          paginationTotalRows={count ?? 10}
          onChangePage={(pageNumber: number) => setCurrentPage(pageNumber)}
          progressPending={loading}
        />
        <p style={{ marginBottom: "-10px", textAlign: "center", fontWeight: "500" }}>* Indicative price subject to reconfirmation</p>
      </Card>
    </>
  );
};

export default ProductDataTable;
