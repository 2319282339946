// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Components Imports
import { Container, Card, InputGroup, Row, Col } from "reactstrap";
import { Btn, H3 } from "../../AbstractElements";

// ** Third Party Imports
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

// ** Custom Imports
import AllQuoteList from "./AllQuote";
import AddQuotes from "./AddQuote";
import CsSearch from "../../Components/CsSearch";
import MultiSelect from "../../Components/MultiSelect";

// ** Store Import
import { setFilter } from "../../ReduxToolkit/Reducer/Quotes";

// ** Styles Imports
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { useWindowResize } from "../../hooks";

const Quotemanage = () => {
  // State
  const [search, setSearch] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [value, setValue] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);

  // Hooks
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: any) => state.layout);
  const { width } = useWindowResize()

  return (
    <div className="page-body">
      <Container fluid className="p-3">
        <Card className="p-3">
          <div className="d-flex pb-3 justify-content-between align-items-center flex-wrap">
            <H3>Quotation Management</H3>
            <Btn
              color="primary"
              className={`${width > 570 ? 'w-fit' : 'w-100'} ms-auto mt-3 mt-sm-0`}
              onClick={() => setOpenDialog(true)}
            >
              Add Quotation
            </Btn>
          </div>

          <Row className="mb-4">
            <Col xs={12} md={4}>
              <CsSearch
                boxClass="w-100"
                name="Search Quotation"
                placeholder="Search Quotation"
                value={search}
                setState={setSearch}
                loading={false}
                handleChange={(val: any) =>
                  dispatch(setFilter({ search: val }))
                }
              />
            </Col>
            <Col xs={12} md={4} className="my-3 my-md-0">
              <MultiSelect
                value={status}
                label='Select Status'
                onChange={(value: any) => {
                  setStatus(value);
                  dispatch(setFilter({ status: value }));
                }}
                options={[
                  { label: "Pending", value: "1" },
                  { label: "Responded", value: "2" },
                ]}
                isMulti={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <InputGroup className="flatpicker-calender d-block w-auto">
                <DatePicker
                  placeholder="Select Dates"
                  format="DD-MM-YYYY"
                  className={darkMode ? "bg-dark" : ""}
                  monthYearSeparator="::"
                  inputClass="form-control"
                  maxDate={new Date(moment().endOf("day").toDate())}
                  range={true}
                  value={value}
                  onChange={(date: any) => {
                    setValue(date);
                    dispatch(
                      setFilter({
                        startDate: date ? date[0]?.format("DD-MM-YYYY") : "",
                        endDate: date ? date[date?.length === 1 ? 0 : 1]?.format("DD-MM-YYYY") : '',
                      })
                    );

                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <AllQuoteList />
        </Card>
      </Container>

      {openDialog && (
        <AddQuotes isOpen={openDialog} toggle={() => setOpenDialog(false)} />
      )}
    </div>
  );
};

export default Quotemanage;
