const mode: any = {
  PRODUCTION: "https://voraspices.tjcg.in",
  DEVELOPMENT: "https://voraadmin.tjcg.in",
  // DEVELOPMENT: " http://localhost:8000",
};

let appMode: any = process.env.REACT_APP_MODE;

if (!appMode || !mode.hasOwnProperty(appMode)) {
  console.error("Invalid or missing app mode. Defaulting to development.");
  appMode = "DEVELOPMENT";
}

const UrlHelper = {
  serverUrl: mode[appMode],
  imgPath: `${mode[appMode]}/api/preview/`,
};

export default UrlHelper;
