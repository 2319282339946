// ** Component Imports
import { Btn, H4, H6, P } from "../../../AbstractElements";

// ** Third Party Imports
import moment from "moment";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { useSelector } from "react-redux";

// ** Store Imports
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";

const QuotationInfo = (props: any) => {
  // Props
  const { isOpen, toggle, data, openChat } = props;

  // Hooks
  const { darkMode } = useSelector((state: any) => state.layout);
  const authData =
    useSelector(auth_data) || AppUtils.getLocalStorage("VORA_CUSTOMER");

  const rowData = [
    { label: "Company Name", value: data?.company_name },
    { label: "Destination", value: data?.destination },
    { label: "Incoterms", value: data?.incoterms },
    { label: "Delivery", value: data?.delivery },
    { label: "Payment Terms", value: data?.payment_terms },
  ];

  const responseData = [
    { label: "Payment Terms", value: data?.response?.payment_terms },
    { label: "Remarks", value: data?.response?.remarks },
    { label: "Delivery Terms", value: data?.response?.delivery },
    {
      label: "Date",
      value: data?.response?.date ? moment(data?.response?.date).format("DD-MM-YYYY hh:mm A") : 'N/A',
    },
  ];

  return (
    <Modal centered={true} isOpen={isOpen} size="lg">
      <ModalHeader toggle={toggle}>
        <H4>Quotation Information</H4>
      </ModalHeader>
      <ModalBody
        style={{
          overflow: "auto",
          height:
            Array.isArray(data?.packing) && data?.packing?.length > 4
              ? "calc(100vh - 12rem)"
              : "auto",
        }}
      >
        {Array.isArray(rowData) &&
          rowData?.length > 0 &&
          rowData?.map((item: any, index: number) => {
            return (
              <Row className="mb-1" key={index}>
                <Col xs="5">
                  <H6>{item?.label} </H6>
                </Col>
                <Col xs="7">
                  <P className="mb-0"><b>:</b> {item?.value ? item?.value : "N/A"}</P>
                </Col>
              </Row>
            );
          })}
        <hr style={{ opacity: "0.1" }} />
        <H4 className="mb-3">Resopnse</H4>
        {data?.response &&
          Array.isArray(responseData) &&
          responseData?.length > 0 &&
          responseData?.map((item: any, index: number) => {
            return (
              <Row className="mb-1" key={index}>
                <Col xs="5">
                  <H6>{item?.label}</H6>
                </Col>
                <Col xs="7">
                  <P className="mb-0"><b>:</b> {item?.value ? item?.value : "N/A"}</P>
                </Col>
              </Row>
            );
          })}
        <div style={{
          overflowX: 'auto',
          width: "100%"
        }}>
          <Table
            className="my-3"
            style={{
              backgroundColor: darkMode ? `rgb(26, 28, 35)` : "#ecf3fa",
              textAlign: "center",
            }}
          >
            <thead>
              <th
                style={{
                  fontWeight: "700",
                  color: darkMode ? "#ffffff" : "inherit",
                }}
              >
                Sr No.
              </th>
              <th
                style={{
                  fontWeight: "700",
                  color: darkMode ? "#ffffff" : "inherit",
                }}
              >
                Product Name
              </th>
              <th
                style={{
                  fontWeight: "700",
                  color: darkMode ? "#ffffff" : "inherit",
                }}
              >
                Total Quantity
              </th>
              <th
                style={{
                  fontWeight: "700",
                  color: darkMode ? "#ffffff" : "inherit",
                }}
              >
                Per Packege Quantity
              </th>
              <th
                style={{
                  fontWeight: "700",
                  color: darkMode ? "#ffffff" : "inherit",
                }}
              >
                Price (per unit)
              </th>
            </thead>
            <tbody>
              {Array.isArray(data?.packing) &&
                data?.packing?.length > 0 &&
                data?.packing?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          fontWeight: 500,
                          color: darkMode ? "#ffffff" : "inherit",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          color: darkMode ? "#ffffff" : "inherit",
                          fontWeight: 500,
                        }}
                      >
                        {AppUtils.checkValue(
                          data?.product_id?.find(
                            (prod: any) => prod?._id === item?.product_id
                          )?.product_name
                        )
                          ? data?.product_id?.find(
                            (prod: any) => prod?._id === item?.product_id
                          )?.product_name
                          : "N/A"}
                      </td>
                      <td
                        style={{
                          color: darkMode ? "#ffffff" : "inherit",
                          fontWeight: 500,
                        }}
                      >
                        {item?.quantity ? item?.quantity : "N/A"}
                      </td>
                      <td
                        style={{
                          color: darkMode ? "#ffffff" : "inherit",
                          fontWeight: 500,
                        }}
                      >
                        {item?.value ? item?.value : "N/A"}
                      </td>
                      <td
                        style={{
                          color: darkMode ? "#ffffff" : "inherit",
                          fontWeight: 500,
                        }}
                      >
                        {/* {Array.isArray(data?.response?.price) &&
                        data?.response?.price?.length > 0
                        ? `${"$"} ${authData?.exchangeRate === 0
                          ? Number(
                            data?.response?.price[index]?.value
                          ).toFixed(0)
                          : (
                            Number(data?.response?.price[index]?.value) *
                            authData?.exchangeRate
                          ).toFixed(0)
                        }/- *`
                        : "N/A"} */}

                        {Array.isArray(data?.response?.price) && data?.response?.price?.length > 0
                          ? `${"$"} ${Number(data?.response?.price[index]?.value).toFixed(2).split('.')[1] === '00'
                            ? Number(data?.response?.price[index]?.value).toFixed(0)
                            : Number(data?.response?.price[index]?.value).toFixed(2)}*`
                          : "N/A"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {Array.isArray(data?.response?.price) && data?.response?.price?.length > 0 && <p style={{ margin: "0 10px", textAlign: "center", fontWeight: "500" }}>* Indicative price subject to reconfirmation</p>}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <Btn
            color="secondary"
            className="ms-3"
            style={{ width: "max-content" }}
            onClick={toggle}
          >
            Close
          </Btn>
          <Btn
            color="primary"
            className="ms-3"
            style={{ width: "max-content" }}
            onClick={openChat}
          >
            Chat
          </Btn>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default QuotationInfo;
