import { Col, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FeatherIcons, LI, UL } from "../../../AbstractElements";
import ResponsiveSearchBar from "./ResponsiveSearchBar/ResponsiveSearchBar";
import SearchBar from "./SearchBar/SearchBar";
import DarkMode from "./DarkMode/DarkMode";
import UserProfile from "./UserProfile/UserProfile";
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";
import Notification from "../Notification";

const RightHeader = () => {

  // Hooks
  const navigate = useNavigate();
  const authData = useSelector(auth_data);

  const homeURL = process.env.REACT_APP_HOME_URL

  return (
    <Col
      xl="7"
      xs="8"
      className="nav-right pull-right right-header p-0 ms-auto"
    >
      <UL className="nav-menus simple-list flex-row">
        <ResponsiveSearchBar />
        <SearchBar />
        <LI onClick={() => window.open(`${homeURL}/login?token=${encodeURIComponent(authData?.token)}`)}>
          <div id="go-to-home-95fjd">
            <FeatherIcons className="home" iconName="Home" />
          </div>
          <UncontrolledTooltip placement="bottom" target={`go-to-home-95fjd`}>
            Home
          </UncontrolledTooltip>
        </LI>
        <DarkMode />
        <Notification />
        <UserProfile />
      </UL>
    </Col>
  );
};

export default RightHeader;
