// ** Components Importa
import { Col, Row, Table } from "reactstrap";
import { H4, H6, P } from "../../../../../AbstractElements";

// ** Third Party Imports
import { useSelector } from "react-redux";
import moment from "moment";

// ** Store Imports
import { curr_quote } from "../../../../../ReduxToolkit/Reducer/Quotes";
import { auth_data } from "../../../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Helper Imports
import AppUtils from "../../../../../Helper/AppUtils";

const LeftSidebar = () => {
  // Hooks
  const currQuote = useSelector(curr_quote);
  const { darkMode } = useSelector((state: any) => state.layout);
  const authData = useSelector(auth_data)

  const rowData = [
    { label: "Company Name", value: currQuote?.company_name },
    { label: "Destination", value: currQuote?.destination },
    { label: "Incoterms", value: currQuote?.incoterms },
    { label: "Delivery", value: currQuote?.delivery },
    { label: "Payment Terms", value: currQuote?.payment_terms },
  ];

  const responseData = [
    // {
    //   label: "Price",
    //   value: `₹ ${data?.response?.price ? data?.response?.price : 0}/-`,
    // },
    { label: "Payment Terms", value: currQuote?.response?.payment_terms },
    { label: "Remarks", value: currQuote?.response?.remarks },
    { label: "Delivery Terms", value: currQuote?.response?.delivery },
    {
      label: "Date",
      value: currQuote?.response?.date ? moment(currQuote?.response?.date).format("DD-MM-YYYY hh:mm A") : "N/A",
    },
  ];

  return (
    <div className="px-3">
      {Array.isArray(rowData) &&
        rowData?.length > 0 &&
        rowData?.map((item: any, index: number) => {
          return (
            <Row className="mb-3" key={index}>
              <Col xs="4">
                <H6 style={{ fontWeight: "unset" }}>
                  <strong>{item?.label}</strong>
                </H6>
              </Col>
              <Col xs="8">
                <P className="mb-0"><b>: </b> {item?.value ? item?.value : "N/A"}</P>
              </Col>
            </Row>
          );
        })}
      <hr style={{ opacity: "0.1" }} />
      <H4 className="mb-3">Resopnse</H4>
      {currQuote?.response &&
        Array.isArray(responseData) &&
        responseData?.length > 0 &&
        responseData?.map((item: any, index: number) => {
          return (
            <Row className="mb-3" key={index}>
              <Col xs="4">
                <H6>{item?.label}</H6>
              </Col>
              <Col xs="8">
                <P className="mb-0"><b>: </b> {item?.value ? item?.value : "N/A"}</P>
              </Col>
            </Row>
          );
        })}
      <Table
        className="my-3"
        style={{
          backgroundColor: darkMode ? `#1a1c23` : "#ecf3fa",
          textAlign: "center",
        }}
      >
        <thead>
          <th
            style={{
              fontWeight: "700",
              color: darkMode ? "#ffffff" : "inherit",
            }}
          >
            Sr No.
          </th>
          <th
            style={{
              fontWeight: "700",
              color: darkMode ? "#ffffff" : "inherit",
            }}
          >
            Product Name
          </th>
          <th
            style={{
              fontWeight: "700",
              color: darkMode ? "#ffffff" : "inherit",
            }}
          >
            Total Quantity
          </th>
          <th
            style={{
              fontWeight: "700",
              color: darkMode ? "#ffffff" : "inherit",
            }}
          >
            Per Packege Quantity
          </th>
          <th
            style={{
              fontWeight: "700",
              color: darkMode ? "#ffffff" : "inherit",
            }}
          >
            Price (per unit)
          </th>
        </thead>
        <tbody>
          {Array.isArray(currQuote?.packing) &&
            currQuote?.packing?.length > 0 &&
            currQuote?.packing?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      fontWeight: 500,
                      color: darkMode ? "#ffffff" : "inherit",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      color: darkMode ? "#ffffff" : "inherit",
                      fontWeight: 500,
                    }}
                  >
                    {AppUtils.checkValue(
                      currQuote?.product_id?.find(
                        (prod: any) => prod?._id === item?.product_id
                      )?.product_name
                    )
                      ? currQuote?.product_id?.find(
                        (prod: any) => prod?._id === item?.product_id
                      )?.product_name
                      : "N/A"}
                  </td>
                  <td
                    style={{
                      color: darkMode ? "#ffffff" : "inherit",
                      fontWeight: 500,
                    }}
                  >
                    {item?.quantity ? item?.quantity : "N/A"}
                  </td>
                  <td
                    style={{
                      color: darkMode ? "#ffffff" : "inherit",
                      fontWeight: 500,
                    }}
                  >
                    {item?.value ? item?.value : "N/A"}
                  </td>
                  <td
                    style={{
                      color: darkMode ? "#ffffff" : "inherit",
                      fontWeight: 500,
                    }}
                  >
                    <p style={{ margin: "0", width: "max-content" }}>
                      {Array.isArray(currQuote?.response?.price) && currQuote?.response?.price?.length > 0
                        ? `${"$"} ${Number(currQuote?.response?.price[index]?.value).toFixed(2).split('.')[1] === '00'
                          ? Number(currQuote?.response?.price[index]?.value).toFixed(0)
                          : Number(currQuote?.response?.price[index]?.value).toFixed(2)}*`
                        : "N/A"}
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {Array.isArray(currQuote?.response?.price) && currQuote?.response?.price?.length > 0 && <p style={{ textAlign: "center", fontWeight: "500" }}>* Indicative price subject to reconfirmation</p>}
    </div>
  );
};

export default LeftSidebar;
