// ** Components Imports
import { Container } from "reactstrap";
import ProductDataTable from "./ProductDataTable";


const ProductList = () => {
  return (
    <div className="page-body">
      <Container fluid={true} className="p-3">
        <ProductDataTable />
      </Container>
    </div>
  );
};

export default ProductList;
