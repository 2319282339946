/* 
import { io } from 'socket.io-client'
import UrlHelper from '../Helper/Url'



export default io(`https://voraadmin.tjcg.in?userId=rHyDN6OBsWm28ueF9PgXs36U`,{
    transports: ["websocket"],
})
 */

import { io } from 'socket.io-client';
import { showBrowserNotification } from './BrowserNotification';

const socket = io('https://voraadmin.tjcg.in', {
  path: '/socket.io',
  query: {
    userId: 'rHyDN6OBsWm28ueF9PgXs36U',
  },
  transports: ['websocket'],
});

socket.on('connect', () => {
  console.log('Connected to server');
});

socket.on('disconnect', () => {
  console.log('Disconnected from server');
});

socket.on('connect_error', (err) => {
  console.error('Connection error:', err);
});


// socket.on("incomingChatMessage", (value: any) => {
//   const message = JSON.parse(value);
//   if (message?.sent_by === 'ADMIN') {
//     showBrowserNotification('VoraSpices Team', { icon: '/assets/images/notification.png', body: message?.message })
//   }
// })

export default socket;
