import apiCall from "../../../Helper/Service";

// ** Slice Imports
import { setLoading, setNotificationCount, setNotificationList, updateNotification } from "./NotificationSlice"

export const getNotificationList = async (pasData: any, dispatch: any) => {
    try {
        dispatch(setLoading(true))

        const res = await apiCall({
            url: "notification/listNotifications",
            data: pasData,
            method: "post",
            isHideTostMsg: true,
        });

        if (res.status) {
            dispatch(setNotificationList(res.data))
            dispatch(setNotificationCount(res.record_count))
        }

        return res
    } catch (error) {
        console.log('Error :: ', error)
        return null
    } finally {
        dispatch(setLoading(false))
    }
}

export const readNotification = async (pasData: any, dispatch: any) => {
    try {
        dispatch(setLoading(true))

        const res = await apiCall({
            url: "notification/readNotification",
            data: pasData,
            method: "post",
            isHideTostMsg: true,
        });

        if (res.status) {
            dispatch(updateNotification(pasData?.id))
        }

        return res
    } catch (error) {
        console.log('Error :: ', error)
        return null
    } finally {
        dispatch(setLoading(false))
    }
}