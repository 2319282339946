// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface initialState {
  isLoading: boolean;
  allMessage: any[];
}

const ChatState: initialState = {
  isLoading: false,
  allMessage: [],
};

const ChatSlice = createSlice({
  name: "chat",
  initialState: ChatState,
  reducers: {
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },

    setAllMessage(state: any, action: any) {
      state.allMessage = action.payload;
    },

    addMessage(state: any, action: any) {
      const isExist = state.allMessage.some(
        (item: any) => item?._id === action.payload?._id
      );
      if (!isExist) {
        state.allMessage.push(action.payload);
      }
    },

    editMessageStatus(state: any, action: any) {
      const { quotation_id, customer_id } = action.payload;
      state.allMessage = state.allMessage.map((message: any) =>
        message.quotation_id === quotation_id &&
        message.customer_id === customer_id
          ? { ...message, is_customer_seen: true }
          : message
      );
    },

    deleteMessages(state: any) {
      state.allMessage = state.allMessage.filter(
        (message: any) => message.sent_by !== "CUSTOMER"
      );
    },
  },
});

export const {
  setLoading,
  setAllMessage,
  addMessage,
  editMessageStatus,
  deleteMessages,
}: any = ChatSlice.actions;

export default ChatSlice.reducer;

export const is_loading = (state: any) => state.chat.isLoading;

export const all_messages = (state: any) => state.chat.allMessage;
