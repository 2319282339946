// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface DashboardState {
  isLoading: boolean;
  allQoutesList: any[];
  allQuotesRecord: number;
  filter: any;
  currQuote: any;
  allCategory: any[];
  allUnits: any[];
}

const dashboardState: DashboardState = {
  isLoading: true,
  allQoutesList: [],
  allQuotesRecord: 0,
  filter: null,
  currQuote: {},
  allCategory: [],
  allUnits: [],
};

const QuotesdSlice = createSlice({
  name: "quote",
  initialState: dashboardState,
  reducers: {
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    setAllQoutesList(state: any, action: any) {
      state.allQoutesList = action.payload;
    },
    setAllQoutesRecords(state: any, action: any) {
      state.allQuotesRecord = action.payload;
    },
    setAllCategories(state: any, action: any) {
      state.allCategory = action.payload;
    },
    setAllUnits(state: any, action: any) {
      state.allUnits = action.payload;
    },
    setFilter(state: any, action: any) {
      state.filter = { ...state.filter, ...action.payload };
    },
    createQuote(state: any, action: any) {
      state.allQoutesList.unshift(action.payload);
    },
    setCurrQuote(state: any, action: any) {
      state.currQuote = action.payload;
      state.allQoutesList = state.allQoutesList.map((quote: any) =>
        quote?._id === action.payload?._id ? action.payload : quote
      );
    },
    setSeen(state: any, action: any) {
      state.allQoutesList = state.allQoutesList.map((item: any) =>
        item?._id === action?.payload?.id ? { ...item, is_seen: true } : item
      );
    },
    updateUnreadCount(state: any, action: any) {
      const { id, unreadCount } = action.payload;
      state.allQoutesList = state.allQoutesList.map((item: any) =>
        item?._id === id ? { ...item, unreadCount } : item
      );
    },
  },
});

export const {
  setLoading,
  setAllQoutesList,
  setAllQoutesRecords,
  createQuote,
  setFilter,
  setSeen,
  setCurrQuote,
  setAllCategories,
  updateUnreadCount,
  setAllUnits
}: any = QuotesdSlice.actions;

export default QuotesdSlice.reducer;

export const is_loading = (state: any) => state.quote.isLoading;

export const all_quotes_data = (state: any) => state.quote.allQoutesList;

export const all_quotes_count = (state: any) => state.quote.allQuotesRecord;

export const quote_filter = (state: any) => state.quote.filter;

export const curr_quote = (state: any) => state.quote.currQuote;

export const all_category = (state: any) => state.quote.allCategory;

export const all_units = (state: any) => state.quote.allUnits;
