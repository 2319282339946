// ** React Imports
import { Row } from "reactstrap";

// ** Third Party Imports
import { useSelector } from "react-redux";

// ** Custom Imports
import SearchInput from "./SearchInput/SearchInput";
import HeaderLogo from "./HeaderLogo/HeaderLogo";
import LeftHeader from "./LeftHeader/LeftHeader";
import RightHeader from "./RightHeader/RightHeader";

const Header = () => {
  // Hooks
  const { toggleSidebar, scroll } = useSelector((state: any) => state.layout);

  return (
    <div
      className={`page-header ${toggleSidebar ? "close_icon" : ""}`}
      id="pageheaders"
      style={{ display: scroll ? "none" : "" }}
    >
      <Row className="header-wrapper m-0">
        <SearchInput />
        <HeaderLogo />
        <LeftHeader />
        <RightHeader />
      </Row>
    </div>
  );
};

export default Header;
