import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Btn, H4, H6, Image, LI, P, UL } from "../../AbstractElements";
import { dynamicImage } from "../../Service";

const CenteredModal = (props: any) => {
  // Props
  const {
    label,
    backdrop,
    size,
    isOpen,
    toggle,
    onDelete,
    note = "",
    btnText = "Delete",
  } = props;

  return (
    <Modal
      backdrop={backdrop}
      centered={true}
      size={size}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody>
        <div className="modal-toggle-wrapper">
          <UL className="modal-img">
            <LI className="text-center">
              <Image src={dynamicImage(`gif/danger.gif`)} alt="error" />
            </LI>
          </UL>
          <H4 className="text-center pb-2">Are you sure ?</H4>
          <P className="text-center">{label}!</P>
          {note && <H6 className="text-center m-0">{note}!</H6>}
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Btn color="secondary" onClick={toggle}>
          Close
        </Btn>
        <Btn color="primary" onClick={onDelete}>
          {btnText}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default CenteredModal;
