// ** Third Party Imports
import { configureStore } from "@reduxjs/toolkit";

// ** Slice Imports
import LayoutSlice from "./Reducer/Layout/LayoutSlice";
import LoginSlice from "./Reducer/Login/LoginSlice";
import DashboardSlice from "./Reducer/Dashboard";
import QuoteSlice from "./Reducer/Quotes";
import LogsSlice from "./Reducer/Logs";
import Chat from "./Reducer/Chat";
import ThemeCustomizerSlice from "./Reducer/Customizer/ThemeCustomizerSlice";
import NotificationSlice from "./Reducer/Notification/NotificationSlice";

const Store = configureStore({
  reducer: {
    layout: LayoutSlice,
    auth: LoginSlice,
    themeCustomizer: ThemeCustomizerSlice,
    dashboard: DashboardSlice,
    quote: QuoteSlice,
    logs: LogsSlice,
    chat: Chat,
    notification: NotificationSlice,
  },
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
