// ** Third party Imports
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

const CsTable = (props: any) => {
  // Props
  const {
    columns,
    data,
    onRowClicked,
    paginationTotalRows,
    onChangePage,
    progressPending,
  } = props;

  // Hooks
  const { darkMode } = useSelector((state: any) => state.layout);

  return (
    <div className="table-responsive">
      <DataTable
        {...props}
        theme={darkMode ? "dark" : "default"}
        columns={columns}
        data={data ?? []}
        pagination
        highlightOnHover
        striped
        persistTableHead
        paginationServer
        onRowClicked={onRowClicked}
        noDataComponent={
          <div className="text-center my-4" style={{ backgroundColor: "" }}>
            <img src="/assets/images/NoDataFound.png" />
          </div>
        }
        pointerOnHover
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10]}
        paginationTotalRows={paginationTotalRows ?? 10}
        onChangePage={onChangePage}
        progressPending={progressPending}
        progressComponent={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            className="my-4"
          >
            <img
              src="/assets/svg/LoaderWhite.svg"
              style={{ width: "3rem", height: "3rem" }}
            />
            <p>Loading...</p>
          </div>
        }
      />
    </div>
  );
};

export default CsTable;
