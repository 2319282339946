// ** React Imports
import { useEffect, useState } from "react";

// ** Component Imports
import { H4, P } from "../../../AbstractElements";
import {
  Row,
  Form,
  Label,
  Modal,
  ModalBody,
  InputGroup,
  ModalFooter,
  ModalHeader,
  Col,
  Input,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// ** Third Party Imports
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

// ** Custom Imprts
import CsTagDropdown from "../../../Components/CsTagDropdown";
import TextField from "../../../Components/TextField";
import CsFooter from "../../../Components/CsFooter";

// ** Store Imports
import {
  addQuotes,
  fetchAllCategories,
  fetchAllUnits,
} from "../../../ReduxToolkit/Reducer/Quotes/QuotesAction";
import {
  all_category,
  all_units,
  is_loading,
} from "../../../ReduxToolkit/Reducer/Quotes";
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";
import Consts from "../../../Helper/Constants";

// Types
interface Quote {
  company_name: string;
  category_id: any;
  destination: string;
  packing: any;
  incoterms: string;
  delivery: string;
  custom_delivery: string;
  payment_terms: string;
  product_id: any;
  custom_terms: string;
}

const AddQuotes = (props: any) => {
  // Props
  const { isOpen, toggle } = props;

  // State
  const [dropdownOpen, setDropdownOpen] = useState<any>(null);

  // Hooks
  const dispatch = useDispatch();
  const loading = useSelector(is_loading);
  const { darkMode } = useSelector((state: any) => state.layout);
  const allCategory = useSelector(all_category);
  const allUnits = useSelector(all_units);
  const authData = useSelector(auth_data);

  const initialValues: Quote = {
    company_name: "",
    category_id: [],
    destination: "",
    packing: [],
    incoterms: "",
    delivery: "",
    custom_delivery: "",
    payment_terms: "",
    product_id: [],
    custom_terms: "",
  };

  const schema = yup.object({
    product_id: yup.array().required("Please select product"),
    category_id: yup.array().required("Please select category"),
    packing: yup.array().of(
      yup.object({
        value: yup.string().required("Please select pack size"),
        quantity: yup.string().required("Please enter quantity"),
        unit: yup.string().required("Please select unit"),
        custom_value: yup.string().test((val: any, context: any) => {
          const value = context.parent.value;
          if (value === "custom" && !AppUtils.checkValue(val)) {
            return context.createError({
              message: "Please enter custom pack size",
            });
          } else return true;
        }),
      })
    ),
    delivery: yup.string().required("Please select delivery"),
    custom_delivery: yup.string().test((val: any, context: any) => {
      const delivery = context.parent.delivery;
      if (delivery === "custom" && !AppUtils.checkValue(val)) {
        return context.createError({
          message: "Please enter custom delivery",
        });
      } else return true;
    }),
    custom_terms: yup.string().test((val: any, context: any) => {
      const terms = context.parent.payment_terms;
      if (terms === "custom" && !AppUtils.checkValue(val)) {
        return context.createError({
          message: "Please enter custom payment terms",
        });
      } else return true;
    }),
    company_name: yup.string().required("Please enter company name"),
    destination: yup.string().required("Please enter destination"),
    incoterms: yup.string().required("Please select incoterms"),
    payment_terms: yup.string().required("Please enter payment terms"),
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value: Quote) => {
      const packing = value?.packing?.map((item: any) => {
        return {
          product_id: item?.product_id,
          quantity: `${item?.quantity} ${item.unit ? item.unit : ""}`,
          value:
            item?.value === "custom"
              ? item?.custom_value
              : item.unit
                ? `${item?.value} ${item.unit}`
                : item?.value,
        };
      });
      const res = await addQuotes(
        {
          company_name: value?.company_name?.trim(),
          destination: value?.destination?.trim(),
          incoterms: value?.incoterms?.trim(),
          payment_terms:
            value?.payment_terms === "custom"
              ? value?.custom_terms?.trim()
              : value?.payment_terms?.trim(),
          category_id: value?.category_id,
          product_id: value?.product_id?.map((item: any) => item?._id),
          delivery:
            value.delivery === "custom"
              ? value?.custom_delivery?.trim()
              : `${value?.delivery?.trim()} days`,
          packing: packing,
        },
        dispatch
      );
      if (res?.status) {
        formik.resetForm();
        toggle();
      }
    },
  });

  useEffect(() => {
    Array.isArray(allCategory) &&
      allCategory.length === 0 &&
      fetchAllCategories(dispatch);
  }, [allCategory?.length]);

  useEffect(() => {
    if (AppUtils.checkValue(authData) && Object?.keys(authData)?.length > 0) {
      formik.setFieldValue(
        "company_name",
        AppUtils.checkValue(authData?.company_name)
          ? authData?.company_name
          : ""
      );
    }
  }, []);

  useEffect(() => {
    Array.isArray(allUnits) &&
      allUnits.length === 0 &&
      fetchAllUnits({ startToken: 1, search: "", endToken: 10 }, dispatch);
  }, [allUnits?.length]);

  return (
    <Modal isOpen={isOpen} fullscreen={true} className="product-modal">
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        <H4>Add Quotation</H4>
      </ModalHeader>
      <ModalBody>
        <Form className="theme-form">
          <Row>
            <Col xs={12} sm={6}>
              <TextField
                label={"Company Name"}
                boxClass="mb-3 w-100"
                name={"company_name"}
                value={formik.values.company_name}
                onChange={formik.handleChange}
                placeholder="Enter Company Name"
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                }
              />
            </Col>
            <Col xs={12} sm={6}>
              <Label>Select Category</Label>
              <div className="mb-3 d-flex flex-wrap checkbox-checked">
                {Array.isArray(allCategory) &&
                  allCategory?.length > 0 &&
                  allCategory?.map((item: any, index: number) => {
                    return (
                      <div className="form-check me-3" key={index}>
                        <Input
                          id={`flexCheckDefault-${item?.value}`}
                          type="checkbox"
                          name="category_id"
                          value={item?.value}
                          checked={formik.values.category_id.includes(
                            item.value
                          )}
                          onChange={() => {
                            const { category_id } = formik.values;
                            if (category_id.includes(item?.value)) {
                              formik.setFieldValue(
                                "category_id",
                                category_id.filter(
                                  (id: any) => id !== item?.value
                                )
                              );
                            } else {
                              formik.setFieldValue("category_id", [
                                ...category_id,
                                item.value,
                              ]);
                            }
                          }}
                        />
                        <Label for={`flexCheckDefault-${item.value}`} check>
                          {item?.label}
                        </Label>
                      </div>
                    );
                  })}
              </div>
              {formik.touched.category_id &&
                Boolean(formik.errors.category_id) && (
                  <div className="text-danger ps-1 pt-1">
                    {formik.errors.category_id}
                  </div>
                )}
            </Col>
            <Col xs={12}>
              <div>
                <CsTagDropdown
                  label={`Select Product ${formik.values.category_id?.length > 0
                    ? ""
                    : "(First Select Category)"
                    }`}
                  placeholder={"Select Product"}
                  isMulti={true}
                  value={formik.values.product_id}
                  key={
                    formik.values.category_id?.length > 0
                      ? Math.random().toString(36).substring(2, 7)
                      : undefined
                  }
                  onChange={(val: any) => {
                    formik.setFieldValue("product_id", val);

                    const updatedPacking = val?.map((item: any) => {
                      const existingPackingEntry = formik.values.packing.find(
                        (packItem: any) => packItem?.product_id === item?._id
                      );

                      return {
                        product_id: item._id,
                        value: existingPackingEntry
                          ? existingPackingEntry.value
                          : "",
                        quantity: existingPackingEntry
                          ? existingPackingEntry.quantity
                          : "",
                        custom_value: existingPackingEntry
                          ? existingPackingEntry.custom_value
                          : "",
                        unit: existingPackingEntry
                          ? existingPackingEntry.unit
                          : "",
                      };
                    });

                    formik.setFieldValue("packing", updatedPacking);
                  }}
                  data={{
                    category_id: formik.values?.category_id,
                  }}
                  isCertificate={true}
                  disabled={formik.values.category_id?.length === 0}
                  getApi="product/getProductDropdown"
                  fieldName="product_name"
                  error={
                    formik.touched.product_id &&
                    Boolean(formik.errors.product_id)
                  }
                  helperText={
                    formik.touched.product_id && formik.errors.product_id
                  }
                />
              </div>
            </Col>
            {Array.isArray(formik.values.packing) &&
              formik.values.packing?.length > 0 && (
                <Col xs={12} className="mt-3">
                  <Card className="p-3 my-2 card-border-dark">
                    <h4
                      className="heading-title-wrapper"
                      style={{
                        backgroundColor: darkMode ? "#22242b" : "white",
                      }}
                    >
                      Packing Details
                    </h4>
                    {formik.values.packing?.map((item: any, index: number) => {
                      return (
                        <Row
                          key={index}
                          style={{
                            border: "0.0625rem solid grey",
                            marginBottom: "1rem",
                            borderRadius: "0.5rem",
                          }}
                          className="mx-1 mt-2 py-3 px-1"
                        >
                          <P style={{ fontWeight: 700 }}>
                            {
                              formik.values?.product_id?.find(
                                (prod: any) => prod?._id === item?.product_id
                              ).value
                            }
                          </P>
                          <Col xs={12} md={item.value === "custom" ? 4 : 6}>
                            <Label
                              style={{ color: darkMode ? "white" : "black" }}
                            >
                              Quantity
                            </Label>
                            <InputGroup size="default">
                              <Input
                                type="text"
                                name="quantity"
                                placeholder="Enter Qunatity"
                                value={item.quantity}
                                onChange={(e: any) => {
                                  formik.setFieldValue(
                                    "packing",
                                    formik.values.packing?.map(
                                      (val: any, i: number) =>
                                        i === index
                                          ? { ...val, quantity: e.target.value }
                                          : { ...val }
                                    )
                                  );
                                }}
                              />
                              <Dropdown
                                isOpen={dropdownOpen === index}
                                toggle={() =>
                                  setDropdownOpen(
                                    dropdownOpen === index ? null : index
                                  )
                                }
                              >
                                <DropdownToggle
                                  caret
                                  color="primary"
                                  style={{
                                    width: "auto",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.unit || "Select Unit"}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {AppUtils.checkValue(allUnits) &&
                                    Array?.isArray(allUnits) &&
                                    allUnits?.length > 0 &&
                                    allUnits?.map((unit: any, i: number) => {
                                      return (
                                        <DropdownItem
                                          className={
                                            unit?.label?.toLowerCase() ===
                                              item?.unit?.toLowerCase()
                                              ? "bg-primary"
                                              : ""
                                          }
                                          style={{
                                            color: "#000",
                                            fontWeight: 600,
                                            borderTop: "1px solid grey",
                                          }}
                                          key={i}
                                          onClick={() => {
                                            formik.setFieldValue(
                                              "packing",
                                              formik.values.packing?.map(
                                                (val: any, i: number) =>
                                                  i === index
                                                    ? {
                                                      ...val,
                                                      unit: unit?.label,
                                                    }
                                                    : { ...val }
                                              )
                                            );
                                          }}
                                        >
                                          {unit?.label}
                                        </DropdownItem>
                                      );
                                    })}
                                </DropdownMenu>
                              </Dropdown>
                            </InputGroup>
                            <div className="d-flex">
                              {((formik.touched.packing?.[index]?.quantity &&
                                Boolean(
                                  formik.errors.packing?.[index]?.quantity
                                )) ||
                                (formik.touched?.packing?.[index]?.unit &&
                                  Boolean(
                                    formik.errors?.packing?.[index]?.unit
                                  ))) && (
                                  <div className="text-danger ps-1 pt-1">
                                    Please enter quantity and select unit
                                  </div>
                                )}
                              {/* { (
                                  <div className="text-danger ps-2 pt-1">
                                    {}
                                  </div>
                                )} */}
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={item.value === "custom" ? 4 : 6}
                            key={index}
                            className="mt-3 mt-md-0"
                          >
                            <Label
                              style={{ color: darkMode ? "white" : "black" }}
                            >
                              Select Pack Size
                            </Label>
                            <Input
                              id="packing"
                              name="packing"
                              type="select"
                              value={item?.value}
                              onChange={(e: any) => {
                                formik.setFieldValue(
                                  "packing",
                                  formik.values.packing?.map(
                                    (val: any, i: number) =>
                                      i === index
                                        ? { ...val, value: e.target.value }
                                        : { ...val }
                                  )
                                );
                              }}
                            >
                              <option>Select Pack Size</option>
                              <option value={"15"}>15 KG</option>
                              <option value={"25"}>25 KG</option>
                              <option value={"50"}>50 KG</option>
                              <option value={"custom"}>Custom</option>
                            </Input>
                            {formik.touched.packing?.[index]?.value &&
                              Boolean(
                                formik.errors.packing?.[index]?.value
                              ) && (
                                <div className="text-danger ps-1 pt-1">
                                  {formik.errors.packing?.[index]?.value}
                                </div>
                              )}
                          </Col>
                          {item.value === "custom" && (
                            <Col xs={12} md={4} className="mt-3 mt-md-0">
                              <TextField
                                type="text"
                                label={"Custom Pack Size"}
                                boxClass="w-100"
                                name={"custom_packing"}
                                value={item?.custom_value}
                                onChange={(e: any) => {
                                  const value = e.target.value;
                                  if (
                                    !Consts.spaceRegex.test(value) &&
                                    /^[a-zA-Z0-9 ]*$/.test(value)
                                  ) {
                                    formik.setFieldValue(
                                      "packing",
                                      formik.values.packing?.map(
                                        (val: any, i: number) =>
                                          i === index
                                            ? {
                                              ...val,
                                              custom_value: value,
                                            }
                                            : { ...val }
                                      )
                                    );
                                  }
                                }}
                                placeholder="Enter Custom Pack Size"
                                error={
                                  formik.touched.packing?.[index]
                                    ?.custom_value &&
                                  Boolean(
                                    formik.errors.packing?.[index]?.custom_value
                                  )
                                }
                                helperText={
                                  formik.touched.packing?.[index]
                                    ?.custom_value &&
                                  formik.errors.packing?.[index]?.custom_value
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                  </Card>
                </Col>
              )}

            <Col xs={12} sm={6}>
              <TextField
                label={"Destination"}
                boxClass="mt-3 w-100"
                name={"destination"}
                value={formik.values.destination}
                onChange={formik.handleChange}
                placeholder="Enter Destination"
                error={
                  formik.touched.destination &&
                  Boolean(formik.errors.destination)
                }
                helperText={
                  formik.touched.destination && formik.errors.destination
                }
              />
            </Col>
            <Col xs={12} sm={6} className="mt-3">
              <Label style={{ color: darkMode ? "white" : "black" }}>
                Select Incoterms
              </Label>
              <Input
                id="incoterms"
                name="incoterms"
                type="select"
                value={formik.values.incoterms}
                onChange={formik.handleChange}
              >
                <option>Select Incoterms</option>
                <option value={"FOB"}>FOB</option>
                <option value={"CIF"}>CIF</option>
                <option value={"CNF"}>CNF</option>
              </Input>
              {formik.touched.incoterms && Boolean(formik.errors.incoterms) && (
                <div className="text-danger ps-1 pt-1">
                  {formik.touched.incoterms &&
                    Boolean(formik.errors.incoterms) &&
                    String(formik.errors.incoterms)}
                </div>
              )}
            </Col>
            <Col
              xs={12}
              sm={formik.values.delivery === "custom" ? 6 : 12}
              className="mt-3"
            >
              <Label style={{ color: darkMode ? "white" : "black" }}>
                Select Delivery (Day)
              </Label>
              <Input
                id="delivery"
                name="delivery"
                type="select"
                value={formik.values.delivery}
                onChange={formik.handleChange}
              >
                <option>Select Delivery</option>
                <option value={"30"}>30 Days</option>
                <option value={"custom"}>Custom</option>
              </Input>
              {formik.touched.delivery && Boolean(formik.errors.delivery) && (
                <div className="text-danger ps-1 pt-1">
                  {formik.touched.delivery &&
                    Boolean(formik.errors.delivery) &&
                    String(formik.errors.delivery)}
                </div>
              )}
            </Col>
            {formik.values.delivery === "custom" && (
              <Col xs={12} sm={6}>
                <TextField
                  type="text"
                  label={"Custom Delivery"}
                  boxClass="mt-3 w-100"
                  name={"custom_delivery"}
                  value={formik.values.custom_delivery}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    if (
                      !Consts.spaceRegex.test(value) &&
                      /^[a-zA-Z0-9 ]*$/.test(value)
                    ) {
                      formik.setFieldValue("custom_delivery", value);
                    }
                  }}
                  placeholder="Enter Custom Delivery"
                  error={
                    formik.touched.custom_delivery &&
                    Boolean(formik.errors.custom_delivery)
                  }
                  helperText={
                    formik.touched.custom_delivery &&
                    formik.errors.custom_delivery
                  }
                />
              </Col>
            )}
            <Col xs={12}>
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="mt-3"
              >
                Select Payment Terms
              </Label>
              <Input
                id="payment_terms"
                name="payment_terms"
                type="select"
                value={formik.values.payment_terms}
                onChange={formik.handleChange}
              >
                <option>Select Payment Terms</option>
                <option
                  value={
                    "20% advance and balance 80% against email of documents within 5 working days"
                  }
                >
                  20% advance and balance 80% against email of documents within
                  5 working days
                </option>
                <option value={"100% CAD through bank"}>
                  100% CAD through bank
                </option>
                <option value={"custom"}>Custom Terms</option>
              </Input>
              {formik.touched.payment_terms &&
                Boolean(formik.errors.payment_terms) && (
                  <div className="text-danger ps-1 pt-1">
                    {formik.errors.payment_terms}
                  </div>
                )}
            </Col>
            {formik.values.payment_terms === "custom" && (
              <Col xs={12}>
                <TextField
                  type="textarea"
                  label={"Custom Payment Terms"}
                  boxClass="mt-3 w-100"
                  name={"custom_terms"}
                  value={formik.values.custom_terms}
                  onChange={formik.handleChange}
                  placeholder="Enter Payment Terms"
                  error={
                    formik.touched.custom_terms &&
                    Boolean(formik.errors.custom_terms)
                  }
                  helperText={
                    formik.touched.custom_terms && formik.errors.custom_terms
                  }
                />
              </Col>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <CsFooter
          type="submit"
          color="primary"
          label="Save"
          isSpace={"pt-0"}
          disabled={loading}
          onClick={formik.handleSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddQuotes;
