// ** React Imports
import { useState, useEffect } from "react";

// ** Componetns Imports
import { Label } from "reactstrap";

// ** Third Party Imports
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";

// ** Helper Imports
import apiCall from "../../Helper/Service";

const CsTagDropdown = (props: any) => {
  // Props
  const {
    createApi,
    getApi,
    fieldName,
    isCertificate,
    isMultiSelect = true,
    setAllData,
    label,
    placeholder,
  } = props;

  // State
  const [displayTitle, setDisplayTitle] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);
  const [optionsLength, setOptionsLength] = useState<number>(1);
  const [active, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateOptionKey, setUpdateOptionKey] = useState<string>(
    Math.random().toString(36).substring(2, 7)
  );

  // Hooks
  const { darkMode } = useSelector((state: any) => state.layout);

  // for async load options
  const changeValue = async (value: any) => {
    if (
      !isCertificate &&
      value &&
      value?.length > 0 &&
      value[value.length - 1]?.isNewCreated &&
      value[value.length - 1]?.isNewCreated == true
    ) {
      const resp = await apiCall({
        isHideTostMsg: true,
        method: "post",
        url: createApi,
        data: {
          [fieldName]: value[value.length - 1]?.value,
        },
      });
      if (resp?.status) {
        if (props?.onChange) {
          props.onChange(value);
        }
      }
    }
    if (props?.onChange) {
      props.onChange(value);
    }
  };

  // for async load options
  const AsyncSelectSearchOptions = async (
    search: string,
    prevOptions: any,
    additional: any
  ) => {
    const { limit, hasMoreAfterInitial } = additional;
    let { initialPageCall, page } = additional;

    page = search !== "" ? (!initialPageCall ? 1 : page) : page;
    try {
      initialPageCall = !initialPageCall
        ? search !== "" && page === 1
        : initialPageCall;
      setLoading(true);
      const data: any = await apiCall({
        url: getApi,
        data: props?.data,
        method: "post",
        isAdmin: true,
        isHideTostMsg: true,
      });
      setLoading(false);
      const newData: any[] = [];

      if (data?.data) {
        const { status } = data;
        if (status) {
          const custListDD = data?.data;
          if (setAllData) {
            setAllData(custListDD);
          }
          if (custListDD?.length > 0) {
            custListDD.forEach((element: any) => {
              newData.push({
                value: element[fieldName],
                label: element[fieldName],
                _id: element?._id,
              });
            });
          }
        }
      }
      const finalData = [...prevOptions, ...newData];
      if (!isCertificate) {
        if (search !== "" && search?.trim() != "") {
          finalData.push({
            isNewCreated: true,
            value: search,
            label: search,
          });
        }
      }

      setOptionsLength(finalData?.length);
      const hasMore = !(newData?.length < limit);
      const slicedOptions = finalData.slice(
        prevOptions?.length,
        prevOptions?.length + limit
      );

      return {
        options: slicedOptions,
        hasMore,
        additional: {
          ...additional,
          page: data?.length === 0 ? page : page + 1,
          hasMoreAfterInitial: hasMoreAfterInitial,
        },
      };
    } catch (e) {
      console.log("client error : ", e);
      return getNullResponseCall(1, additional, hasMoreAfterInitial);
    }
  };

  const getNullResponseCall = async (
    page: number,
    additional: any,
    hasMoreAfterInitial: boolean
  ) => {
    return {
      options: [],
      hasMore: false,
      additional: {
        ...additional,
        page: page,
        hasMoreAfterInitial: hasMoreAfterInitial,
      },
    };
  };

  useEffect(() => {
    if (props?.key) {
      setUpdateOptionKey(props?.key);
    }
  }, [props?.key]);

  return (
    <>
      <div
        className="d-flex flex-column position-relative"
        style={{ height: "100%", width: "100%" }}
      >
        {label && (
          <Label style={{ color: darkMode ? "white" : "black" }}>
            {label ? label : "Tags"}
          </Label>
        )}
        <div className="d-flex position-relative w-100">
          <AsyncPaginate
            key={updateOptionKey}
            menuIsOpen={open}
            placeholder={placeholder ? placeholder : "Tags"}
            isMulti={isMultiSelect}
            id={"tags"}
            name={"tags"}
            className={"selectAsyncBox w-100"}
            value={props.value}
            debounceTimeout={400}
            loadOptions={AsyncSelectSearchOptions}
            isLoading={loading}
            isDisabled={props.disabled}
            onChange={changeValue}
            additional={{
              page: 1,
              limit: 20,
              initialPageCall: true,
              hasMoreAfterInitial: true,
            }}
            autoFocus={focus}
            onFocus={() => {
              setActive(true);
              setDisplayTitle(true);
              setFocus(true);
            }}
            onMenuOpen={() => {
              setOpen(true);
              setDisplayTitle(true);
              setFocus(false);
            }}
            onMenuClose={() => {
              setOpen(false);
              setDisplayTitle(false);
              setFocus(false);
            }}
            onBlur={() => setActive(false)}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor: darkMode
                  ? "#22242b"
                  : state.isDisabled
                  ? "rgba(0, 0, 0, 0.1)"
                  : "white",
                color: darkMode ? "#86909c" : "black",
                borderColor: darkMode ? "rgba(255, 255, 255, 0.1)" : "#dee2e6",
                borderRadius: "0.375rem",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: darkMode ? "#86909c" : "black",
              }),
              input: (provided) => ({
                ...provided,
                color: darkMode ? "#86909c" : "black",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: darkMode ? "#86909c" : "black",
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: darkMode ? "#22242b" : "white",
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "lightgrey"
                  : darkMode
                  ? "#22242b"
                  : "white",
                color: darkMode ? "#86909c" : "black",
                "&:hover": {
                  backgroundColor: "lightgrey",
                },
              }),
            }}
          />
        </div>
        {props.error && (
          <Label
            className="ms-2"
            style={{ color: "#FF4D49", borderRadius: "10px" }}
          >
            {props.helperText}
          </Label>
        )}
      </div>
    </>
  );
};

export default CsTagDropdown;
