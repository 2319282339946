// ** Pages Imports
import Dashboard from "../Pages/Dashboard";
import LoginLogs from "../Pages/Logs";
import ProductList from "../Pages/Products";
import ProfilePage from "../Pages/Profile";
import Quotemanage from "../Pages/Quotation";

const Routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/quotation`, Component: <Quotemanage /> },
  { path: `${process.env.PUBLIC_URL}/logs`, Component: <LoginLogs /> },
  { path: `${process.env.PUBLIC_URL}/product-list`, Component: <ProductList /> },
  { path: `${process.env.PUBLIC_URL}/profile`, Component: <ProfilePage /> },
];

export default Routes;
