// ** React Imports
import { useEffect } from "react";

// ** Components Imports
import { Col } from "reactstrap";
import { H4, Image } from "../../../AbstractElements";

// ** Third Party Imports
import { Link } from "react-router-dom";
import { dynamicImage } from "../../../Service";
import { useSelector } from "react-redux";

// ** Store Imports
import { auth_data } from "../../../ReduxToolkit/Reducer/Login/LoginSlice";
import { setExchangeRate } from "../../../ReduxToolkit/Reducer/Login/LoginAction";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";

const LeftHeader = () => {
  // Hooks
  const logindata = useSelector(auth_data);

  // useEffect(() => {
  //   setExchangeRate(logindata?.country?.currency);
  // });

  return (
    <Col xl="5" lg="4" md="4" sm="3" className="left-header p-0">
      <div>
        <Link className="toggle-sidebar" to={"#"}>
          <i className="iconly-Category"></i>
        </Link>
        <div className="d-flex align-items-center gap-2">
          <H4 className="f-w-600">
            Welcome{" "}
            {AppUtils.checkValue(logindata?.name) ? logindata?.name : ""}
          </H4>
          <Image
            className="mt-0"
            src={dynamicImage("hand.gif")}
            alt="hand-gif"
          />
        </div>
      </div>
      <div className="welcome-content d-xl-block d-none">
        <Col xs="12" className="">
          Here’s a look at your store's overall activity and key information.
        </Col>
      </div>
    </Col>
  );
};

export default LeftHeader;
