
// ** Slice Imports
import { setLoading, setLoginHistory, setLoginHistoryCount } from ".";

// ** Helper Imports
import apiCall from "../../../Helper/Service";

export const fetchLoginHistory = async (passData: any, dispatch: any) => {
    dispatch(setLoading(true));

    const res = await apiCall({
        url: "auth/getCustomerLoginHistoryData",
        data: passData,
        method: "POST",
        isHideTostMsg: true,
    });

    if (res?.status) {
        dispatch(setLoginHistory(res?.data));
        dispatch(setLoginHistoryCount(res?.record_count));
        dispatch(setLoading(false));
    }

    return res;
};