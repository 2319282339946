// ** Service Imports
import apiCall, { customApiCall } from "../../../Helper/Service";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";

// ** Slice Imports
import { onSuccessfulLogin, setLoading } from "./LoginSlice";

export const customerLogin = async (
  passData: any,
  dispatch: any,
  rememberMe: boolean
) => {
  const res = await apiCall({
    url: "auth/signIn",
    data: passData,
    method: "post",
    isLogin: false,
  });

  if (res && res?.status_code === 200 && res?.data) {
    rememberMe
      ? AppUtils.setLocalStorage(
        "VORA_CUS_REM",
        JSON.stringify({
          uN: AppUtils.encodeStr(passData.email),
          uP: AppUtils.encodeStr(passData.password),
        })
      )
      : AppUtils.removeLocalStorageItem("VORA_CUS_REM");

    AppUtils.setLocalStorage(
      "VORA_CUSTOMER",
      AppUtils.encodeStr(JSON.stringify(res?.data))
    );
    dispatch(onSuccessfulLogin(res?.data));
  }
  return res;
};

export const changePassword = async (passData: any, dispatch: any) => {
  const res = await apiCall({
    url: "auth/setPassword",
    data: passData,
    method: "post",
    isLogin: false,
  });

  // if (res && res?.status_code === 200 && res?.data) {
  //   AppUtils.setLocalStorage(
  //     "VORA_CUS_REM",
  //     JSON.stringify({
  //       uP: AppUtils.encodeStr(passData.password),
  //     })
  //   );
  // }

  return res;
};

export const setExchangeRate = async (currency: any) => {
  const res = await customApiCall({
    method: "GET",
    url: `https://api.exchangerate-api.com/v4/latest/INR`,
  });

  if (res?.status && res?.data && res?.data) {
    const { rates } = res?.data;
    if (AppUtils.checkValue(rates) && Object?.keys(rates)?.length > 0) {
      const data = AppUtils.getLocalStorage("VORA_CUSTOMER");
      const newdata = {
        ...data,
        exchangeRate: rates[currency],
      };

      AppUtils.setLocalStorage(
        "VORA_CUSTOMER",
        AppUtils.encodeStr(JSON.stringify(newdata))
      );
    }
  }

  return res;
};

export const fetchCusomerById = async (passData: any, dispatch: any) => {
  const { id, token } = passData
  const res = await apiCall({
    url: "auth/getCustomerById",
    data: { id },
    method: "post",
    isLogin: false,
  });

  if (res?.status && res?.data && res?.data) {
    const updatedRes = { ...res?.data, token: token }
    AppUtils.setLocalStorage(
      "VORA_CUSTOMER",
      AppUtils.encodeStr(JSON.stringify(updatedRes))
    );
    AppUtils.removeLocalStorageItem('TODAY_NEWS')
    AppUtils.removeLocalStorageItem('CURR_DATE')
    dispatch(onSuccessfulLogin(res?.data));
  }

  return res;
};


export const updateUserProfile = async (passData: any, dispatch: any) => {
  try {
    dispatch(setLoading(true))
    const res = await apiCall({
      url: "auth/updateCustomer",
      data: passData,
      method: "post",
    });

    const data = AppUtils.getLocalStorage("VORA_CUSTOMER");
    AppUtils.setLocalStorage("VORA_CUSTOMER", AppUtils.encodeStr(JSON.stringify({ ...res?.data, token: data?.token })));
    AppUtils.removeLocalStorageItem('TODAY_NEWS')
    AppUtils.removeLocalStorageItem('CURR_DATE')
    dispatch(onSuccessfulLogin({ ...data, ...res?.data }))

    return res
  } catch (error) {
    console.error('Error :: ', error)
  } finally {
    dispatch(setLoading(false))
  }
}