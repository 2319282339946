import { Btn } from "../../AbstractElements";

const CsFooter = (props: any) => {
  // Props
  const { label, color, type, className, onClick, disabled, isSpace } = props;

  return (
    <div
      className={`card-footer mt-2 px-0 ${
        isSpace ? isSpace : "pt-3"
      } text-end pb-0`}
    >
      <Btn
        type={type ? type : "submit"}
        color={color ? color : "primary"}
        className={className}
        style={{ width: "max-content" }}
        onClick={onClick}
        disabled={disabled}
      >
        {disabled ? (
          <img
            alt=""
            src="assets/svg/LoaderBlack.svg"
            style={{
              width: "1.2rem",
              marginLeft: "0.4rem",
              marginRight: "0.4rem",
            }}
          />
        ) : (
          label
        )}
      </Btn>
    </div>
  );
};

export default CsFooter;
