// ** Third Party Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** Helper Imports
import AppUtils from "../../../Helper/AppUtils";

interface InitialAuthState {
  authData: any;
  isLoading: boolean;
}

const initialAuthState: InitialAuthState = {
  authData: AppUtils.getLocalStorage("VORA_CUSTOMER") || {},
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    onSuccessfulLogin(state: any, action: any) {
      state.authData = action.payload;
    },
    setLoading(state: any, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    onLogout(state: any) {
      return state;
    },
  },
});

export const { onSuccessfulLogin, onLogout, setLoading } =
  authSlice.actions;

export default authSlice.reducer;

export const auth_data = (state: any) => state.auth.authData;

export const is_loading = (state: any) => state.auth.isLoading;
