// ** Slice Imports
import {
  setLoading,
  setInquiryData,
  setProductList,
  setNewsData,
  setInquiryRecord,
  setProductRecord,
  setAllCategories,
  setTypeOfSpices
} from ".";

// ** Helper Imports
import apiCall, { customApiCall } from "../../../Helper/Service";
import Consts from "../../../Helper/Constants";
import AppUtils from "../../../Helper/AppUtils";

export const fetchInquiryData = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "product/getInquiryHistory",
    data: passData,
    method: "POST",
    isHideTostMsg: true,
    isAdmin: true,
  });

  if (res?.status) {
    dispatch(setInquiryData(res?.data));
    dispatch(setInquiryRecord(res?.record_count));
    dispatch(setLoading(false));
  }

  return res;
};

export const fetchProductList = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "dashboard/getProductList",
    data: passData,
    method: "POST",
    isHideTostMsg: true,
  });

  if (res?.status) {
    dispatch(setProductList(res?.data));
    dispatch(setProductRecord(res?.record_count));
    dispatch(setLoading(false));
  }

  return res;
};

export const fetchNewsData = async (passData: any, dispatch: any) => {
  // dispatch(setLoading(true));

  const { date, country } = passData;

  const res = await customApiCall({
    method: "GET",
    url: `https://api.worldnewsapi.com/search-news?source-countries=${country}&earliest-publish-date=${date}`,
    headers: {
      "x-api-key": Consts?.newsAPIKey,
    },
  });

  if (res.status === 200) {
    AppUtils.setLocalStorage(
      "CURR_DATE",
      AppUtils.encodeStr(JSON.stringify({ dt: date }))
    );
    AppUtils.setLocalStorage(
      "TODAY_NEWS",
      AppUtils.encodeStr(JSON.stringify({ tn: res?.data?.news }))
    );
    dispatch(setNewsData(res?.data?.news));
    // dispatch(setLoading(false));
  }

  return res;
};

export const fetchAllCategoryList = async (passData: any, dispatch: any) => {
  dispatch(setLoading(true));

  const res = await apiCall({
    url: "category/categoryList",
    data: passData,
    method: "POST",
    isAdmin: true,
    isHideTostMsg: true,
  });

  if (res?.status) {
    dispatch(setAllCategories(res?.data));
    dispatch(setLoading(false));
  }

  return res;
};


export const fetchTypeOfSpices = async (dispatch: any) => {
  const res = await apiCall({
    url: "product/getTypeOfSpicesList",
    data: {},
    method: "post",
    isHideTostMsg: true,
    isAdmin: true,
  });

  if (res?.status) {
    dispatch(setTypeOfSpices(res?.data));
  }
};
